import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetAdditionalServicesQueryVariables = Types.Exact<{
    [key: string]: never
}>

export type GetAdditionalServicesQuery = {
    __typename: 'Query'
    additionalServices: Array<{
        __typename: 'AdditionalService'
        id: number
        code: string
        title: string
        multiple: boolean
        sort: number
        price: string
    }>
}

export type CheckPromoCodeQueryVariables = Types.Exact<{
    promoCode: Types.Scalars['String']
}>

export type CheckPromoCodeQuery = {
    __typename: 'Query'
    checkPromoCode: boolean
}

export const GetAdditionalServicesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetAdditionalServices' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalServices' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiple' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetAdditionalServicesQuery__
 *
 * To run a query within a React component, call `useGetAdditionalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdditionalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdditionalServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdditionalServicesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAdditionalServicesQuery,
        GetAdditionalServicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetAdditionalServicesQuery,
        GetAdditionalServicesQueryVariables
    >(GetAdditionalServicesDocument, options)
}
export function useGetAdditionalServicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAdditionalServicesQuery,
        GetAdditionalServicesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetAdditionalServicesQuery,
        GetAdditionalServicesQueryVariables
    >(GetAdditionalServicesDocument, options)
}
export type GetAdditionalServicesQueryHookResult = ReturnType<
    typeof useGetAdditionalServicesQuery
>
export type GetAdditionalServicesLazyQueryHookResult = ReturnType<
    typeof useGetAdditionalServicesLazyQuery
>
export type GetAdditionalServicesQueryResult = Apollo.QueryResult<
    GetAdditionalServicesQuery,
    GetAdditionalServicesQueryVariables
>
export const CheckPromoCodeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CheckPromoCode' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'promoCode' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'checkPromoCode' },
                        name: { kind: 'Name', value: 'checkPromoCode' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'promoCode' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'promoCode' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useCheckPromoCodeQuery__
 *
 * To run a query within a React component, call `useCheckPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPromoCodeQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useCheckPromoCodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        CheckPromoCodeQuery,
        CheckPromoCodeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CheckPromoCodeQuery, CheckPromoCodeQueryVariables>(
        CheckPromoCodeDocument,
        options
    )
}
export function useCheckPromoCodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CheckPromoCodeQuery,
        CheckPromoCodeQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        CheckPromoCodeQuery,
        CheckPromoCodeQueryVariables
    >(CheckPromoCodeDocument, options)
}
export type CheckPromoCodeQueryHookResult = ReturnType<
    typeof useCheckPromoCodeQuery
>
export type CheckPromoCodeLazyQueryHookResult = ReturnType<
    typeof useCheckPromoCodeLazyQuery
>
export type CheckPromoCodeQueryResult = Apollo.QueryResult<
    CheckPromoCodeQuery,
    CheckPromoCodeQueryVariables
>
