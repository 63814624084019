import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Input from 'react-phone-number-input/input'
import AuthLayout from '../../layouts/AuthLayout'

export const EnterPhone = ({
    handlePhoneNumber
}: {
    handlePhoneNumber: (phoneNumber: string) => void
}) => {
    const navigate = useNavigate()
    const [phoneError, setPhoneError] = React.useState<string>(
        'Телефон не может быть пустым'
    )
    const [formValid, setFormValid] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>('')
    const [phone, setPhone] = React.useState<string>()

    const handleSubmit = () => {
        if (phone) {
            handlePhoneNumber(phone)
        }
    }

    const handlePhoneChange = (value: string) => {
        if (value) {
            if (value.length <= 11) {
                setPhoneError('Телефон не может быть пустым')
            } else {
                setPhoneError('')
            }
            setPhone(value)
        }
    }

    React.useEffect(() => {
        if (phoneError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (error) {
            setTimeout(() => setError(''), 3000)
        }
    }, [error, phoneError])

    return (
        <AuthLayout header={'Введи номер телефона <br /> для замены пароля'}>
            <div>
                <h3 className='registration__numbers'>ТЕЛЕФОН</h3>
                <Input
                    autoFocus
                    // international
                    // defaultCountry='RU'
                    className={
                        error
                            ? 'registration__input activeError'
                            : 'registration__input'
                    }
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder='+7 999 999 9999'
                />
                {error ? <div className='error'>{error}</div> : null}
                <button
                    className='registration__button registration__button_signIn'
                    style={{
                        backgroundColor: formValid ? '#48C0F3' : '#E1E3E6',
                        color: formValid ? '#FFFFFF' : '#FFFFFF'
                    }}
                    type='submit'
                    onClick={handleSubmit}
                    disabled={!formValid}
                >
                    Восстановить пароль
                </button>
                <div className='description'> Если у вас еще нет аккаунта</div>
                <button
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/register', { replace: true })}
                >
                    Зарегистрируйтесь здесь
                </button>
                <div className='description'>
                    {' '}
                    Если у вас есть аккаунт, нажмите
                </div>
                <button
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/login', { replace: true })}
                >
                    Войти
                </button>
            </div>
        </AuthLayout>
    )
}
