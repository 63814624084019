import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    modal: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 30px 30px rgba(72, 192, 243, 0.15)',
        borderRadius: '15px',
        width: '704px',
        height: '690px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: 700,
        marginTop: '130px',
        marginBottom: '57px',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.2px',
        textAlign: 'center'
    },
    label: {
        width: '321px',
        height: '20px',
        fontFamily: 'Inter',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        lineHeight: '20px',
        marginLeft: '15px',
        marginBottom: '7px',
        textTransform: 'uppercase'
    },
    input: {
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#3E4958',
        width: '321px',
        height: '44px',
        padding: '5px',
        background: '#F7F8F9',
        border: '0.5px solid #D5DDE0',
        borderRadius: '15px',
        marginBottom: '20px',
        textAlign: 'center'
    },
    text: {
        textAlign: 'center',
        color: '#48C0F3',
        marginTop: '7px',
        textDecoration: 'underline'
    },
    description: {
        fontSize: '15px',
        lineHeight: '28px',
        letterSpacing: '0.2px',
        color: '#97ADB6',
        textAlign: 'center'
    },
    button: {
        width: '321px',
        height: '60px',
        color: '#FFFFFF',
        backgroundColor: '#48C0F3',
        boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '28px',
        textTransform: 'inherit',
        marginTop: '22px',
        marginBottom: '58px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#48C0F3'
        }
    },
    button_register: {
        width: '220px',
        height: '20px',
        fontSize: '15px',
        textAlign: 'center',
        color: '#48C0F3',
        textTransform: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }
    },
    button_forget: {
        width: '150px',
        height: '16px',
        fontSize: '15px',
        color: '#48C0F3',
        textTransform: 'inherit',
        margitTop: '13px',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }
    }
})

export const UpdatePassword = ({
    handlePassword
}: {
    handlePassword: (password: string) => void
}) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        handlePassword(data.get('password')!.toString())
    }

    const classes = useStyles()

    return (
        <Box className={classes.modal}>
            <Box
                className={classes.content}
                component='form'
                onSubmit={handleSubmit}
            >
                <Box className={classes.title}>Введи новый пароль</Box>
                <Box className={classes.label}>Пароль</Box>
                <input
                    className={classes.input}
                    id='phone'
                    name='password'
                    type='password'
                />
                <Button className={classes.button} type='submit'>
                    Подтвердить новый пароль
                </Button>
            </Box>
        </Box>
    )
}
