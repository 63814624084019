import React, { createContext, Dispatch, useReducer } from 'react'
import { profileFormReducer, ShowProfileActionsType } from './reducers'

type InitialStateType = {
    showProfileForm: boolean
}

const initialState = {
    showProfileForm: false
}

const mainReducer = (
    { showProfileForm }: InitialStateType,
    action: ShowProfileActionsType
) => ({
    showProfileForm: profileFormReducer(showProfileForm, action)
})

const AppContext = createContext<{
    state: InitialStateType
    dispatch: Dispatch<ShowProfileActionsType>
}>({
    state: initialState,
    dispatch: () => null
})

const StateProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState)

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export { StateProvider, AppContext }
