import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import CloseButtonSvg from '../../../assets/images/close-button.svg'
import CopySvg from '../../../assets/images/copy.svg'
import './requisites.css'

const modalRootElement = document.querySelector('#modal')

export const Requisites = ({
    isOpenWindow,
    closeModal
}: {
    isOpenWindow: boolean
    closeModal: () => void
}) => {
    const element = useMemo(() => document.createElement('div'), [])
    const [isCopied, setIsCopied] = useState(false)

    useEffect(() => {
        if (isOpenWindow) {
            modalRootElement?.appendChild(element)

            return () => {
                modalRootElement?.removeChild(element)
            }
        }
    })

    function copyToClipboard() {
        navigator.clipboard.writeText(
            `
            ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ ″ТАКСИ КИДС″
            Сокр. название ООО ″ТАКСИ КИДС″
            ИНН 5406819167
            ОГРН 1225400003091
            КПП 540601001
            ОКПО 74444304
            ОКАТО 50401368000
            р/c №40702810201500117602
            Реквизиты банка: 
            Банк: ТОЧКА ПАО БАНКА ″ФК ОТКРЫТИЕ″ 
            БИК: 044525999 
            Город: г. Москва 
            Корр.счёт: 30101810845250000999
            Адрес регистрации (юр.адрес, фактический адрес): 630004, Новосибирская область, г. Новосибирск, ул. Димитрова 1/1, офис 312
            По всем возникающим вопросам вы можете обратиться по телефону нашей горячей линии или написать на почту ceo@kids.taxi
            `
        )
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 3000)
    }

    if (isOpenWindow)
        return createPortal(
            <div className='modal-requisites'>
                <div
                    className='modal-requisites__cancel-button'
                    onClick={closeModal}
                >
                    <img
                        className='close-button'
                        src={CloseButtonSvg}
                        alt='close-button'
                    />
                </div>
                <div className='modal-requisites__container'>
                    <h4 className='modal-requisites__title'>
                        Реквизиты и контакты
                    </h4>
                    <div className='modal-requisites__content'>
                        <div className='modal-requisites__item'>
                            ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ ″ТАКСИ
                            КИДС″
                        </div>
                        <div className='modal-requisites__item'>
                            Сокр. название ООО ″ТАКСИ КИДС″
                            <br />
                            ИНН 5406819167
                            <br />
                            ОГРН 1225400003091
                            <br />
                            КПП 540601001
                            <br />
                            ОКПО 74444304
                            <br />
                            ОКАТО 50401368000
                        </div>
                        <div className='modal-requisites__item'>
                            р/c №40702810201500117602
                        </div>
                        <div className='modal-requisites__item'>
                            Реквизиты банка:
                            <br />
                            Банк: ТОЧКА ПАО БАНКА ″ФК ОТКРЫТИЕ″
                            <br />
                            БИК: 044525999
                            <br />
                            Город: г. Москва
                            <br />
                            Корр.счёт: 30101810845250000999
                        </div>
                        <div className='modal-requisites__item'>
                            Адрес регистрации (юр.адрес, фактический адрес):
                            630004, Новосибирская область, г. Новосибирск, ул.
                            Димитрова 1/1, офис 312
                        </div>
                        <div className='modal-requisites__item'>
                            По всем возникающим вопросам вы можете обратиться по
                            телефону нашей горячей линии или написать на почту
                            ceo@kids.taxi
                        </div>
                    </div>
                    <button
                        className='modal-requisites__button'
                        onClick={copyToClipboard}
                    >
                        {isCopied ? 'Скопировано' : 'Копировать'}{' '}
                        <img src={CopySvg} alt='' />
                    </button>
                </div>
            </div>,
            element
        )
    return null
}
