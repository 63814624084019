import React from 'react'
import { Requisites } from './Requisites'

export function Footer() {
    const [isOpenWindow, setIsOpenWindow] = React.useState(false)

    const closeModal = () => {
        setIsOpenWindow(false)
    }

    return (
        <>
            <div className='footer'>
                <h2 className='heading-2'>
                    ООО «Такси Кидс». Свидетельство о регистрации 1225400003091,
                    ИНН 5406819167 Адрес регистрации: г. Новосибирск, ул.
                    Димитрова 1/1, офис 312
                    <br />
                </h2>
                <h2 className='heading-2'>
                    ООО «Такси Кидс» обрабатывает данные в соответствии с
                    требования 152-ФЗ и состоит в реестре операторов данных
                    (регистрационный номер 54-22-005604).
                    <br />
                </h2>
                <div
                    className='requisites'
                    onClick={() => setIsOpenWindow(true)}
                >
                    Реквизиты и контакты
                </div>
            </div>
            <Requisites isOpenWindow={isOpenWindow} closeModal={closeModal} />
        </>
    )
}
