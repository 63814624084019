import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { RegistrationFormData } from './SignUp'
import AuthLayout from 'components/layouts/AuthLayout'
import PhoneInput from 'components/UI/PhoneInput'
import EyeSvg from 'assets/images/eye.svg'
import EyeClosedSvg from 'assets/images/eye_closed.svg'

export default function Registration({
    handleFromData
}: {
    handleFromData: (data: RegistrationFormData) => void
}) {
    const navigate = useNavigate()
    const initialValues = { phoneNumber: '', password: '' }
    const [formValues, setFormValues] =
        React.useState<{ phoneNumber: string; password: string }>(initialValues)
    const [error, setError] = React.useState<string>('')
    const [phoneError, setPhoneError] = React.useState<string>(
        'Телефон не может быть пустым'
    )
    const [passwordShown, setPasswordShown] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState<string>(
        'Пароль не может быть пустым'
    )
    const [formValid, setFormValid] = React.useState<boolean>(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleFromData(formValues)
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { name, value } = e.target
        if (value.length === 0) {
            setPasswordError('Пароль не может быть пустым')
        } else {
            setPasswordError('')
        }
        setFormValues({ ...formValues, [name]: value })
    }

    const handlePhoneChange = (value: string) => {
        if (value) {
            if (value.length <= 11) {
                setPhoneError('Телефон не может быть пустым')
            } else {
                setPhoneError('')
            }
        }
        setFormValues({ ...formValues, phoneNumber: value })
    }

    React.useEffect(() => {
        if (phoneError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (error) {
            setTimeout(() => setError(''), 3000)
        }
    }, [error, phoneError, passwordError])

    return (
        <AuthLayout header={'Шаг 1. Регистрация'}>
            <form onSubmit={handleSubmit} className='registration__form'>
                <PhoneInput
                    error={error}
                    handlePhoneChange={handlePhoneChange}
                />
                <h3 className='registration__numbers'>ПАРОЛЬ</h3>
                <div className='registration__pass'>
                    <input
                        className='registration__input'
                        name='password'
                        id='password'
                        type={passwordShown ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                        required
                    />

                    <img
                        src={passwordShown ? EyeClosedSvg : EyeSvg}
                        alt=''
                        width='30'
                        height='30'
                        onClick={togglePasswordVisiblity}
                        className='registration__eye'
                    />
                </div>
                <button
                    className='registration__button'
                    type='submit'
                    style={{
                        backgroundColor: formValid ? '#48C0F3' : '#E1E3E6',
                        color: formValid ? '#FFFFFF' : '#FFFFFF'
                    }}
                >
                    Зарегистрироваться
                </button>
            </form>

            <div className='registration__login'>
                <p className='registration__paragraph'>Уже есть аккаунт?</p>
                <button
                    className='registration__link'
                    onClick={() => navigate('/login', { replace: true })}
                >
                    Войти
                </button>
            </div>
        </AuthLayout>
    )
}
