import * as React from 'react'
import { useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import CodeCheck from '../../components/screens/CodeCheck'
import Registration from './Registration'

export interface RegistrationFormData {
    phoneNumber: string
    password: string
}

export default function SignUp() {
    const [formData, setRegistrationData] = useState<RegistrationFormData>(
        null!
    )
    const { signUp } = React.useContext(AuthContext)

    const prepareData = (data: RegistrationFormData) => {
        setRegistrationData(data)
    }

    const validateCode = (otp: string) => {
        signUp({ ...formData, ...{ otp: otp } })
    }

    return formData ? (
        <CodeCheck
            phoneNumber={formData.phoneNumber}
            handleCodeValidated={validateCode}
        />
    ) : (
        <Registration handleFromData={prepareData} />
    )
}
