import React from 'react'
import MapLayer from '../../components/MapLayer'
import NavBar from '../../components/NavBar'
import OrderPanel from '../../components/OrderPanel'
import RouteBuilder from '../../components/RouteBuilder'
import MapProvider from '../../components/providers/MapProvider'
import ProfileProvider from '../../components/providers/ProfileProvider'

export function MainPage() {
    return (
        <>
            <ProfileProvider>
                <>
                    <NavBar />
                    <MapProvider>
                        <>
                            <RouteBuilder />
                            <MapLayer />
                            <div className='div-transition' />
                            <OrderPanel />
                        </>
                    </MapProvider>
                </>
            </ProfileProvider>
        </>
    )
}
