import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import SignIn from '../pages/Auth/SignIn'
import React from 'react'
import { AuthContext } from '../context/AuthContext'
import SignUp from '../pages/Auth/SignUp'
import MainPage from '../pages/MainPage'
import Loader from '../pages/Loader'
import { Recovery } from '../pages/Auth/Recovery'
import TelegramBot from './screens/TelegramBot'
// import { LandingPage } from '../pages/LandingPage'
import { ForParentsPage } from '../pages/ForParentsPage'
import { Auth } from '../pages/Auth/Auth'

export default function RootRouter() {
    // function RequireAuth({ children }: { children: JSX.Element }) {
    //     const { state } = React.useContext(AuthContext)
    //     return state.userToken ? children : <Navigate to='/login' replace />
    // }

    function AuthRedirect({ children }: { children: JSX.Element }) {
        const { state } = React.useContext(AuthContext)
        return state.userToken ? <Navigate to='/' replace /> : children
    }

    const { state, bootstrap } = React.useContext(AuthContext)

    React.useEffect(() => {
        bootstrap()
    }, [])

    if (state.isLoading) return <Loader />

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/auth'
                    element={
                        // <AuthRedirect>
                        <Auth />
                        // </AuthRedirect>
                    }
                />
                <Route
                    path='/login'
                    element={
                        <AuthRedirect>
                            <SignIn />
                        </AuthRedirect>
                    }
                />
                <Route
                    path='/register'
                    element={
                        <AuthRedirect>
                            <SignUp />
                        </AuthRedirect>
                    }
                />
                <Route
                    path='/recovery'
                    element={
                        <AuthRedirect>
                            <Recovery />
                        </AuthRedirect>
                    }
                />
                <Route
                    path='/telegram'
                    element={
                        <AuthRedirect>
                            <TelegramBot />
                        </AuthRedirect>
                    }
                />
                <Route path='/' element={<MainPage />}></Route>
                <Route path='/parents' element={<ForParentsPage />} />
            </Routes>
        </BrowserRouter>
    )
}
