import React, { useState } from 'react'
import { useGetProfileQuery} from './__generated__/profile'
import { ProfileContext } from '../../../context/ProfileContext'
import {ParentProfile} from '../../../__generated__/types';

export const ProfileProvider = ({ children }: { children: JSX.Element }) => {
    const { data, loading } = useGetProfileQuery({})
    const [profile, setProfile] = useState<ParentProfile | null>(null)

    React.useEffect(() => {
        if (data?.profile && !loading) setProfile(data.profile)
    }, [data, loading])

    return (
        <ProfileContext.Provider value={profile}>
            {children}
        </ProfileContext.Provider>
    )
}
