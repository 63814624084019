import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import CarTypeSelect from './CarTypeSelect'
import DriverTypeSelect from './DriverTypeSelect'
import Options from './Options'
import DateSelect from './DateSelect'
import { MapContext } from '../../context/MapContext'
import ChairsSelect from './ChairsSelect'
import './OrderPanel.css'
import { AddAddress } from './AddAddress'
import { FullRoutes } from './FullRoutes/FullRoutes'
import { ShortRoutes } from './ShortRoutes/ShortRoutes'
import { Comment } from './Comment/Comment'
import { OrderButton } from './OrderButton/OrderButton'
import { DropResult } from 'react-beautiful-dnd'

export function OrderPanel() {
    const { points, distance, updateOrderPoints } = React.useContext(MapContext)

    const [toggleActiveWay, setToggleActiveWay] = useState(true)
    const [pointsLength, setPointsLength] = useState(0)
    const [hidePanel, setHidePanel] = useState(false)

    // Следим за изменением длины points из MapContext и меняем стейт
    useEffect(() => {
        setPointsLength(points.length)
    }, [points.length])

    const handleHideStateChange = () => setHidePanel(!hidePanel)

    const handleOnDragEnd = (result: DropResult) => {
        if (result.destination) {
            if (result.source.index !== result.destination.index) {
                const items = Array.from(points)
                const [reorderedPoint] = items.splice(result.source.index, 1)
                items.splice(result.destination.index, 0, reorderedPoint)
                updateOrderPoints(items)
            }
        }
    }
    const handleChangeToggleActiveWay = () => {
        setToggleActiveWay(!toggleActiveWay)
    }

    return (
        <>
            <div
                className={
                    hidePanel ? 'orderPanel orderPanel_hide' : 'orderPanel'
                }
            >
                <div
                    className={
                        hidePanel
                            ? 'orderPanel__header orderPanel__header_hide'
                            : 'orderPanel__header'
                    }
                    onClick={handleHideStateChange}
                ></div>
                <div
                    className='orderPanel__swipebtn'
                    onClick={handleHideStateChange}
                >
                    <div className='orderPanel__swipebtn-shape'></div>
                </div>

                {toggleActiveWay && pointsLength > 2 ? (
                    <ShortRoutes
                        hide={hidePanel}
                        changeToggleActiveWay={handleChangeToggleActiveWay}
                        points={points}
                    />
                ) : (
                    <FullRoutes
                        hide={hidePanel}
                        handleOnDragEnd={handleOnDragEnd}
                        points={points}
                    />
                )}
                <div
                    className={
                        hidePanel
                            ? 'orderPanel__options orderPanel__options_hide'
                            : 'orderPanel__options'
                    }
                >
                    <div className='options__option'>
                        <div className='option__adress'>
                            <div className='option__adress-left'>
                                <img
                                    src='https://uploads-ssl.webflow.com/61f36156ea53421f11e2920d/61f3c45a34a4592d4b3564a5_Group%20127.svg'
                                    alt=''
                                    className='halt-icon-img'
                                />
                            </div>
                            <div className='option__adress-right'>
                                <AddAddress point={points[points.length]} />
                            </div>
                        </div>
                    </div>
                    <DateSelect />
                    <DriverTypeSelect />
                    <CarTypeSelect />
                    <ChairsSelect />
                    <Options />
                    <Comment />
                </div>
                <OrderButton
                    distance={distance}
                    points={points}
                    hide={hidePanel}
                    hideChange={handleHideStateChange}
                />
            </div>
            <Footer />
        </>
    )
}
