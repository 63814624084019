import { useContext } from 'react'
// import CloseButtonSvg from '../../../../assets/images/close-button.svg'
// import CloseButtonTopSvg from '../../../../assets/images/close-button-top.svg'
import { ProfileContext } from '../../../../context/ProfileContext'

interface OptionsProps {
    closeModal: () => void
    title: string
    price: string
    serviceIndex: number
    length: number
    changeCheckedState: any
    checkedState: any
}

export const ModalKids = (props: OptionsProps) => {
    const profile = useContext(ProfileContext)

    function renderServiceCheckbox(array: any) {
        return array.map((item: any, index: number) => (
            <a
                className='service'
                key={index}
                onClick={(e: any) => {
                    e.preventDefault()
                    props.changeCheckedState(props.serviceIndex, index)
                }}
            >
                <div style={{ display: 'flex' }}>
                    <input
                        type='checkbox'
                        className='custom-check'
                        id='check'
                        value='new'
                        checked={props.checkedState[props.serviceIndex][index]}
                        readOnly
                    />

                    <label htmlFor='check'>{item.name}</label>
                    <label>{item.age}&nbsp;года</label>
                </div>

                <span className='price'>{'+' + props.price}</span>
            </a>
        ))
    }

    return (
        <div className='index_modal'>
            <p className='optionText'>{props.title}</p>
            <div className='modal-list'>
                {renderServiceCheckbox(profile ? profile.kids : 0)}
            </div>
            {/* <textarea
                className='text_nanny'
                name='text_nanny'
                id='nanny'
                cols={10}
                rows={3}
                placeholder='Комментарий'
            ></textarea> */}
            <div className='button_container'>
                <button className='total_button' onClick={props.closeModal}>
                    Готово
                </button>
                {/* <button
                    className='close_button'
                    onClick={props.closeModal}
                >
                    <img src={CloseButtonSvg} alt='close-button' />
                </button> */}
            </div>
            {/* <button className='close_button_top' onClick={props.closeModal}>
                <img src={CloseButtonTopSvg} alt='close-button-top' />
            </button> */}
        </div>
    )
}
