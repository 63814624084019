import ServiceItem from '../ServiceItem'
import { useContext } from 'react'
import { ProfileContext } from '../../../../context/ProfileContext'
import { ModalLayout } from '../../../layouts/ModalLayout/ModalLayout'
import styles from './ModalNanny.module.css'
import {AdditionalService, InputMaybe, OrderAdditionalServiceInput} from '../../../../__generated__/types';

interface PropsType {
    optionsList: Array<AdditionalService>
    closeModal: () => void
    options:
        | InputMaybe<Array<InputMaybe<OrderAdditionalServiceInput>>>
        | undefined
    toggleClickOptionsHandler: (
        services: Array<OrderAdditionalServiceInput>
    ) => void
    changeCheckedState: (addIndex: number, kidIndex: number) => void
    checkedState: Array<Array<boolean> | boolean>
    cancelOrder: () => void
    isOpenModalNanny: boolean
}

export const ModalNanny = (props: PropsType) => {
    const profile = useContext(ProfileContext)

    const handleOptions = () => {
        let a: OrderAdditionalServiceInput = {
            additionalServiceId: 0,
            kidIds: []
        }
        let b: Array<number> = []
        let arr: Array<OrderAdditionalServiceInput> | undefined = []
        let data: any = [...props.checkedState]
        for (let i: number = 0; i < data.length; i++) {
            if (Array.isArray(data[i])) {
                for (let j: number = 0; j < data[i].length; j++) {
                    if (data[i][j] && profile) {
                        b.push(profile.kids[j].id)
                    }
                }
                if (b.length != 0) {
                    a = {
                        additionalServiceId: props.optionsList[i].id,
                        kidIds: b
                    }
                    arr.push(a)
                    b = []
                }
            } else if (data[i]) {
                a = { additionalServiceId: props.optionsList[i].id }
                arr.push(a)
            }
        }
        props.toggleClickOptionsHandler(arr)
        props.closeModal()
    }

    return (
        <ModalLayout open={props.isOpenModalNanny} onClose={props.closeModal}>
            <h3 className={styles.title}>Услуги няни</h3>
            <div className={styles.list}>
                {props.optionsList &&
                    props.optionsList.map(
                        (item: AdditionalService, key: number) => (
                            <ServiceItem
                                serviceItem={item}
                                serviceIndex={key}
                                key={key}
                                checkedState={props.checkedState}
                                changeCheckedState={props.changeCheckedState}
                            />
                        )
                    )}
            </div>
            <div className={styles.buttonContainer}>
                {profile ? (
                    <div className={styles.button} onClick={handleOptions}>
                        Добавить
                    </div>
                ) : null}
            </div>
        </ModalLayout>
    )
}
