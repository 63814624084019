import { useEffect, useRef, useState } from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import { useNavigate } from 'react-router-dom'

export const CodeCheck = ({
    phoneNumber,
    handleCodeValidated
}: {
    phoneNumber: string
    handleCodeValidated: (otp: string) => void
}) => {
    const [otp, setOtp] = useState(new Array(4).fill(''))
    const [statusButton, setStatusButton] = useState<boolean>(false)
    const [code, setCode] = useState<boolean>(true)
    const inputRef = useRef<HTMLInputElement>(null)
    const [status, setStatus] = useState<boolean>(false)
    const [timer, setTimer] = useState<number>(30)
    const [timerIsStarted, setTimerIsStarted] = useState(false)
    const navigate = useNavigate()

    const handleChange = (element: any, index: number) => {
        if (isNaN(element.value)) return false
        const value = otp.map((d, idx) => (idx === index ? element.value : d))
        setOtp(value)
        if (element.value !== '')
            element.nextSibling
                ? element.nextSibling.focus()
                : validateCheckCode(parseInt(value.join('')))
    }

    const clearInput = (element: any, el: any) => {
        if (element.code == 'Backspace') {
            if (el.previousSibling) el.previousSibling.focus()
        }
    }
    const authUri = process.env.REACT_APP_AUTH_URI

    const sendCheckCode = async () => {
        fetch(authUri + '/send-code', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: phoneNumber
            })
        })
            .then((response) => {
                console.log('code sent')
                startTimer()
                return response.json()
            })
            .then((json) => {
                if (json.error == 'Телеграм не привязан') setStatus(true)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const validateCheckCode = async (code: number) => {
        fetch(authUri + '/validate-code', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: phoneNumber,
                checkCode: code
            })
        })
            .then((response) => {
                return response.json()
            })

            .then((json) => {
                console.log(json)

                if (json.otp) handleCodeValidated(json.otp)
                if (json.error) {
                    setCode(false)
                    setOtp(new Array(4).fill(''))
                    inputRef.current?.focus()
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const startTimer = () => setTimerIsStarted(true)

    const stopTimer = () => setTimerIsStarted(false)

    useEffect(() => {
        // sendCheckCode()
        inputRef.current?.focus()
    }, [])

    // Timer

    useEffect(() => {
        if (timerIsStarted && timer > 0) {
            const myInterval: NodeJS.Timer = setInterval(() => {
                setTimer(timer - 1)
            }, 1000)
            return () => clearInterval(myInterval)
        }
        if (timer === 0) {
            stopTimer()
            setStatusButton(false)
            setTimer(30)
        }
    }, [timerIsStarted, timer])

    if (status) navigate('/telegram', { replace: true })

    return (
        <AuthLayout header={' Шаг 2.<br/>Подтверждение'}>
            <div>
                <div className='confirmation__info'>
                    Для подтверждения мы совершим звонок-сброс. Введите
                    последние 4 цифры номера, с которого вам позвонили
                </div>

                <form className='confirmation__form'>
                    {otp.map((data, index) => {
                        if (index === 0) {
                            return (
                                <input
                                    className='confirmation__input'
                                    type='text'
                                    inputMode='tel'
                                    name={`otp${index}`}
                                    ref={inputRef}
                                    maxLength={1}
                                    key={index}
                                    value={data}
                                    onChange={(e) =>
                                        handleChange(e.target, index)
                                    }
                                    onFocus={(e) => e.target.select()}
                                    onKeyUp={(e) => clearInput(e, e.target)}
                                />
                            )
                        } else {
                            return (
                                <input
                                    className='confirmation__input'
                                    type='text'
                                    inputMode='tel'
                                    name={`otp${index}`}
                                    maxLength={1}
                                    key={index}
                                    value={data}
                                    onChange={(e) =>
                                        handleChange(e.target, index)
                                    }
                                    onFocus={(e) => e.target.select()}
                                    onKeyUp={(e) => clearInput(e, e.target)}
                                />
                            )
                        }
                    })}
                </form>

                {code ? (
                    ''
                ) : (
                    <div className='confirmation__wrongcode'>
                        Неверный код. Попробуйте еще раз
                    </div>
                )}

                <div className='confirmation__link-container'>
                    <button
                        className={
                            statusButton
                                ? 'registration__button registration__button_disabled'
                                : 'registration__button'
                        }
                        onClick={() => {
                            sendCheckCode()
                            setStatusButton(true)
                        }}
                        disabled={statusButton}
                    >
                        Запросить звонок
                    </button>
                </div>
                {timerIsStarted ? (
                    <div className='confirmation__timer'>
                        Повторный запрос через {timer} секунд
                    </div>
                ) : (
                    ''
                )}
            </div>
        </AuthLayout>
    )
}
