import React, { useEffect } from 'react'
import { load } from '@2gis/mapgl'
import { MapContext } from '../../context/MapContext'
import { Directions } from '@2gis/mapgl-directions'

const MapWrapper = React.memo(
    () => {
        return (
            <div id='map-container' style={{ width: '100%', height: '100%' }} />
        )
    },
    () => true
)
MapWrapper.displayName = 'MapWrapper'

export const MapLayer = () => {
    const {
        setMapInstance,
        setDirections,
        addPointByCoords,
        directionsLoadedHandler,
        addMarker
    } = React.useContext(MapContext)

    useEffect(() => {
        let map: any

        load().then((mapglAPI) => {

            map = new mapglAPI.Map('map-container', {
                center: [43.59034342757725, 56.51125658421523],
                zoom: 13,
                key: '868b89fb-50ab-4d15-a2c0-856d8b46f720',
                style: '5fc43374-9281-4395-b029-f7cd67d80510',
                zoomControl: false
            }).on('click', (ev) => {
                const coords = ev.lngLat

               addMarker(
                    new mapglAPI.Marker(map, {
                        coordinates: coords,
                        icon: 'https://docs.2gis.com/img/dotMarker.svg'
                    })
                )
                
                addPointByCoords(coords)
            })

            const directions = new Directions(map, {
                directionsApiKey: 'ruddwj1064'
            }).on('directionsLoaded', directionsLoadedHandler)

            setMapInstance(map)
            setDirections(directions)
        })

        return () => map && map.destroy()
    }, [])

    return (
        <div className='div-map'>
            <MapWrapper />
        </div>
    )
}

