import React from 'react'

export interface LoginData {
    phoneNumber: string
    password: string
}

export interface RegistrationData {
    phoneNumber: string
    password: string
    otp: string
}

export interface AuthData {
    phoneNumber: string
    otp: string
}

export interface State {
    isLoading: boolean
    isSignOut: boolean
    userToken: string | null
}

interface AuthContextType {
    state: State
    auth: (data: AuthData, isNewStatus: (isNew: boolean) => void) => void
    signIn: (data: LoginData, errorHandler: (error: any) => void) => void
    signOut: () => void
    signUp: (data: RegistrationData) => void
    resetPassword: (data: RegistrationData) => void
    bootstrap: () => void
    changeDashboardName: (data: string) => void
}

export const AuthContext = React.createContext<AuthContextType>(null!)
