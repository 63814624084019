import React, { useEffect } from 'react'
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    from,
    InMemoryCache
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import {
    AuthContext,
    AuthData,
    LoginData,
    RegistrationData
} from './context/AuthContext'
import RootRouter from './components/RootRouter'
import { onError } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils'
import { StateProvider } from './store/context'
import { useDetectSize } from './hooks/useDetectSize'
import { isMobileVar } from './store/cache'

interface Action {
    token?: string | null
    type?: string
    data?: string
}

export default function App() {
    const apiUri = process.env.REACT_APP_API_URI
    const authUri = process.env.REACT_APP_AUTH_URI

    const [windowDimension] = useDetectSize()

    useEffect(() => {
        windowDimension.winWidth < 1280 ? isMobileVar(true) : isMobileVar(false)
    }, [windowDimension])

    const [state, dispatch] = React.useReducer(
        (prevState: any, action: Action) => {
            switch (action.type) {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false
                    }
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token
                    }
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null
                    }
            }
        },
        {
            isLoading: true,
            isSignout: false,
            userToken: null
        }
    )

    const authContext = React.useMemo(
        () => ({
            state,
            auth: async (
                data: AuthData,
                isNewStatus: (isNew: boolean) => void
            ) => {
                fetch(authUri + '/auth', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phoneNumber: data.phoneNumber,
                        otp: data.otp
                    })
                })
                    .then((response) => response.json())
                    .then((json) => {
                        localStorage.setItem('userToken', json.token)
                        dispatch({ type: 'SIGN_IN', token: json.token })
                        console.log(json)
                        json.isNew ? isNewStatus(true) : isNewStatus(false)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            },
            signIn: async (
                data: LoginData,
                errorHandler: (mes: any) => void
            ) => {
                fetch(authUri + '/login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: data.phoneNumber,
                        password: data.password
                    })
                })
                    .then((response) => {
                        console.log(response.ok)
                        if (response.ok) {
                            console.log('Отлично')
                            return response.json()
                        } else {
                            console.log('Проблемка')
                            if (response.status === 401) {
                                console.log('ошибка 401')
                                errorHandler(
                                    'Неверное имя пользователя или пароль'
                                )
                            } else {
                                throw Error(
                                    response.status + ': ' + response.statusText
                                )
                            }
                        }
                    })
                    .then((json) => {
                        localStorage.setItem('userToken', json.token)
                        dispatch({ type: 'SIGN_IN', token: json.token })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            signOut: () => {
                console.log('signOut')
                localStorage.removeItem('userToken')
                return dispatch({ type: 'SIGN_OUT' })
            },
            signUp: async (data: RegistrationData) => {
                fetch(authUri + '/parent-sign-up', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phoneNumber: data.phoneNumber,
                        otp: data.otp,
                        password: data.password
                    })
                })
                    .then((response) => response.json())
                    .then((json) => {
                        localStorage.setItem('userToken', json.token)
                        dispatch({ type: 'SIGN_IN', token: json.token })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            },
            resetPassword: async (data: RegistrationData) => {
                fetch(authUri + '/reset-password', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phoneNumber: data.phoneNumber,
                        otp: data.otp,
                        password: data.password
                    })
                })
                    .then((response) => response.json())
                    .then((json) => {
                        localStorage.setItem('userToken', json.token)
                        dispatch({ type: 'SIGN_IN', token: json.token })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            },
            bootstrap: () => {
                let userToken

                try {
                    userToken = localStorage.getItem('userToken')
                } catch (e) {
                    // Restoring token failed
                }
                dispatch({ type: 'RESTORE_TOKEN', token: userToken })
            },
            changeDashboardName: (string: string) => {
                console.log('changeDashboardName')
                dispatch({ type: 'CHANGE_DASHBOARD_NAME', data: string })
            }
        }),
        [state]
    )

    const uploadLink = createUploadLink({
        uri: apiUri
    })

    const authLink = new ApolloLink((operation, forward) => {
        const token = authContext.state.userToken

        operation.setContext(({ headers = {} }) => ({
            headers: token
                ? {
                      Authorization: `Bearer ${token}`,
                      ...headers
                  }
                : null
        }))

        return forward(operation)
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        let sErr = networkError as ServerError
        if (sErr && sErr.statusCode === 401) authContext.signOut()
    })

    const client = new ApolloClient({
        link: from([errorLink, authLink, uploadLink]),
        cache: new InMemoryCache()
    })

    return (
        <StateProvider>
            <AuthContext.Provider value={authContext}>
                <ApolloProvider client={client}>
                    <RootRouter />
                </ApolloProvider>
            </AuthContext.Provider>
        </StateProvider>
    )
}
