import React from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import EyeSvg from '../../../assets/images/eye.svg'
import EyeClosedSvg from '../../../assets/images/eye_closed.svg'
import { useNavigate } from 'react-router-dom'

export const AlternativeEntry = ({
    phoneNumber,
    otp
}: {
    phoneNumber: string
    otp: string
}) => {
    const [passwordShown, setPasswordShown] = React.useState<boolean>(false)
    const [password, setPassword] = React.useState<string>('')
    const navigate = useNavigate()
    const authUri = process.env.REACT_APP_AUTH_URI

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }
    const handleSubmit = () => {
        fetch(authUri + '/reset-password', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: phoneNumber,
                otp: otp,
                password: password
            })
        })
            .then((response) => response.json())
            .then((json) => {
                localStorage.setItem('userToken', json.token)
            })
            .then(() => {
                navigate('/', { replace: true })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <AuthLayout header={'Альтернативный вход'}>
            <form onSubmit={handleSubmit}>
                <p className='alternative__info'>
                    Вы можете установить пароль для альтернативного входа.
                    Полезно в тех случаях, когда код подтвержения может не
                    приходить.
                </p>
                <div className='registration__pass'>
                    <input
                        className={'registration__input'}
                        name='password'
                        type={passwordShown ? 'text' : 'password'}
                        id='password'
                        onChange={(e) => {
                            e.preventDefault()
                            setPassword(e.currentTarget.value)
                        }}
                    />
                    <img
                        src={passwordShown ? EyeClosedSvg : EyeSvg}
                        alt=''
                        width='30'
                        height='30'
                        onClick={togglePasswordVisiblity}
                        className='registration__eye'
                    />
                </div>
                <button
                    className='registration__button registration__button_signIn'
                    style={{
                        backgroundColor: '#48C0F3',
                        color: '#FFFFFF'
                    }}
                    type='submit'
                >
                    Сохранить
                </button>
                <a
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/', { replace: true })}
                >
                    Пропустить
                </a>
            </form>
        </AuthLayout>
    )
}
