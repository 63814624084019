import * as React from 'react'
// import { AuthContext } from '../../context/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { AlternativeEntry } from 'components/screens/AlternativeEntry'
import AuthLayout from 'components/layouts/AuthLayout'
import CodeCheck from 'components/screens/CodeCheck'
import PhoneInput from 'components/UI/PhoneInput'
import { AuthContext } from '../../context/AuthContext'

export const Auth = () => {
    // const authUri = process.env.REACT_APP_AUTH_URI
    const [phoneNumber, setPhoneNumber] = React.useState<string>(null!)
    const [otp, setOtp] = React.useState<string>(null!)
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false)
    const [isNew, setIsNew] = React.useState<boolean>(false)
    const { auth } = React.useContext(AuthContext)

    const [phoneError, setPhoneError] = React.useState<string>(
        'Телефон не может быть пустым'
    )
    const [error, setError] = React.useState<string>('')
    const [formValid, setFormValid] = React.useState<boolean>(false)

    const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSubmit(true)
    }

    const handlePhoneChange = (str: string) => {
        if (str) {
            if (str.length <= 11) {
                setPhoneError('Телефон не может быть пустым')
            } else {
                setPhoneError('')
            }
        }
        setPhoneNumber(str)
    }
    const isNewChange = (isNew: boolean) => {
        setIsNew(isNew)
    }
    const authNumber = async () => {
        console.log('auth request')
        auth({ phoneNumber: phoneNumber, otp: otp }, isNewChange)
    }

    const validateCode = (otp: string) => {
        setOtp(otp)
    }

    React.useEffect(() => {
        if (phoneError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (error) {
            setTimeout(() => setError(''), 3000)
        }
    }, [error, phoneError])

    React.useEffect(() => {
        if (phoneNumber && otp) {
            authNumber()
        }
    }, [phoneNumber, otp])

    return isSubmit ? (
        otp ? (
            isNew ? (
                <AlternativeEntry phoneNumber={phoneNumber} otp={otp} />
            ) : (
                <Navigate to='/' replace={true} />
            )
        ) : (
            <CodeCheck
                phoneNumber={phoneNumber}
                handleCodeValidated={validateCode}
            />
        )
    ) : (
        <AuthLayout header={'Привет, дорогой друг!'}>
            <form onSubmit={handleSubmit}>
                <PhoneInput
                    error={error}
                    handlePhoneChange={handlePhoneChange}
                />
                <button
                    className='registration__button registration__button_signIn'
                    style={{
                        backgroundColor: formValid ? '#48C0F3' : '#E1E3E6',
                        color: formValid ? '#FFFFFF' : '#FFFFFF'
                    }}
                    type='submit'
                >
                    Продолжить
                </button>
                <div className='description'>Или</div>
                <button
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/login', { replace: true })}
                >
                    Войти с паролем
                </button>
            </form>
        </AuthLayout>
    )
}
