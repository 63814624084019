import * as React from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import AuthLayout from 'components/layouts/AuthLayout'
import PhoneInput from 'components/UI/PhoneInput'
import EyeSvg from 'assets/images/eye.svg'
import EyeClosedSvg from 'assets/images/eye_closed.svg'

const initialValues = { phoneNumber: '', password: '' }

export default function SignIn() {
    const { signIn } = React.useContext(AuthContext)
    const [formValues, setFormValues] =
        React.useState<{ phoneNumber: string; password: string }>(initialValues)
    const [phoneError, setPhoneError] = React.useState<string>(
        'Телефон не может быть пустым'
    )
    const [passwordError, setPasswordError] = React.useState<string>(
        'Пароль не может быть пустым'
    )
    const [error, setError] = React.useState<string>('')
    const [formValid, setFormValid] = React.useState<boolean>(false)
    const [passwordShown, setPasswordShown] = React.useState(false)

    const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        signIn(formValues, errorHandler)
    }

    const errorHandler = (mes: string) => {
        setError(mes)
    }

    const handlePhoneChange = (str: string) => {
        if (str) {
            if (str.length <= 11) {
                setPhoneError('Телефон не может быть пустым')
            } else {
                setPhoneError('')
            }
        }
        setFormValues({ ...formValues, phoneNumber: str })
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
        if (value.length === 0) {
            setPasswordError('Пароль не может быть пустым')
        } else {
            setPasswordError('')
        }
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    React.useEffect(() => {
        if (phoneError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (error) {
            setTimeout(() => setError(''), 3000)
        }
    }, [error, phoneError, passwordError])

    return (
        <AuthLayout header={'Привет, дорогой друг!<br/>Мы скучали'}>
            <form onSubmit={handleSubmit}>
                <PhoneInput
                    error={error}
                    handlePhoneChange={handlePhoneChange}
                />
                <h3 className='registration__numbers'>ПАРОЛЬ</h3>
                <div className='registration__pass'>
                    <input
                        className={
                            error
                                ? 'registration__input activeError'
                                : 'registration__input'
                        }
                        name='password'
                        type={passwordShown ? 'text' : 'password'}
                        id='password'
                        onChange={handlePasswordChange}
                    />
                    <img
                        src={passwordShown ? EyeClosedSvg : EyeSvg}
                        alt=''
                        width='30'
                        height='30'
                        onClick={togglePasswordVisiblity}
                        className='registration__eye'
                    />
                </div>
                {error ? <div className='error'>{error}</div> : null}
                <a
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/recovery', { replace: true })}
                >
                    Забыли пароль?
                </a>
                <button
                    className='registration__button registration__button_signIn'
                    style={{
                        backgroundColor: formValid ? '#48C0F3' : '#E1E3E6',
                        color: formValid ? '#FFFFFF' : '#FFFFFF'
                    }}
                    type='submit'
                >
                    Войти
                </button>
                <div className='description'> Если у вас еще нет аккаунта</div>
                <button
                    className='registration__link registration__link_signIn'
                    onClick={() => navigate('/register', { replace: true })}
                >
                    Зарегистрируйтесь здесь
                </button>
            </form>
        </AuthLayout>
    )
}
