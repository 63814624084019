import React, { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

interface LinkProps {
    to: string
    className: string
}

export const CustomLink: FC<PropsWithChildren<LinkProps>> = ({
    children,
    to,
    className
}) => {
    return (
        <Link to={to} className={className}>
            {children}
        </Link>
    )
}

export default React.memo(CustomLink)
