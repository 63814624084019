import { useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import CloseButtonSvg from '../../assets/images/close-button.svg'

const modalRootElement = document.querySelector('#modal')

export const SupportModal = ({
    closeModal,
    showSupport
}: {
    closeModal: () => void
    showSupport: boolean
}) => {
    const element = useMemo(() => document.createElement('div'), [])

    useEffect(() => {
        if (showSupport) {
            modalRootElement?.appendChild(element)

            return () => {
                modalRootElement?.removeChild(element)
            }
        }
    })
    
    if (showSupport)
    return createPortal(
        <div className='modal__promocode'>
            <div className='modal__cancel-button' onClick={closeModal}>
                <img src={CloseButtonSvg} alt='close-button' />
            </div>
            <div className='modal__container'>
                <h4 className='modal__title'>Поддержка</h4>
                <p className='modal__text'>Номер телефона : +7 800 300 56 57</p>
                <div className='modal__button'>
                    <a className='modal__button_a' href='tel:+78003005657'>
                        Позвонить
                    </a>
                </div>
            </div>
        </div>,
        element
    )
    return null
}
