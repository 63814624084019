import React, { useContext, useEffect, useState } from 'react'
import PromocodeSvg from '../../../assets/images/promocode.svg'
import NannySvg from '../../../assets/images/nanny.svg'
import ArrowSvg from '../../../assets/images/arrow.svg'
import './Options.css'
import ModalNanny from './ModalNanny'
import { ProfileContext } from '../../../context/ProfileContext'
import ModalPromocode from './ModalPromocode'
import { useReactiveVar } from '@apollo/client'
import { calculateOrderVar, placeOrderVar } from '../../../store/cache'
import {useGetAdditionalServicesQuery} from './__generated__/services';
import {AdditionalService, OrderAdditionalServiceInput} from '../../../__generated__/types';

// import { ModalPay } from './ModalPay'

export function Options() {
    const profile = useContext(ProfileContext)
    const { loading, data } = useGetAdditionalServicesQuery({})
    const [optionsList, setOptionslist] = useState<Array<AdditionalService>>(
        null!
    )
    const [isOpenModalNanny, setIsOpenModalNanny] = useState(false)
    const [isOpenModalPromocode, setIsOpenModalPromocode] = useState(false)
    // const [isOpenModalPay, setIsOpenModalPay] = useState(false)

    const [checkedState, setCheckedState] = useState<
        Array<Array<boolean> | boolean>
    >(null!)

    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)

    useEffect(() => {
        if (!loading && data) {
            setOptionslist(data?.additionalServices)
        }
    }, [loading, data])

    useEffect(() => {
        if (optionsList)
            setCheckedState(
                optionsList.map(
                    (value: any) =>
                        (value =
                            value.multiple === true
                                ? new Array(
                                      profile ? profile.kids.length : 0
                                  ).fill(false)
                                : false)
                )
            )
    }, [optionsList, profile])

    const changeCheckedState = (addIndex: number, kidIndex: number) => {
        let data: any = [...checkedState]

        for (let i: number = 0; i < data.length; i++) {
            if (i === addIndex) {
                if (typeof data[i] === 'boolean') {
                    data[i] = !data[i]
                }
                if (Array.isArray(data[i]) && typeof kidIndex === 'number') {
                    data[i][kidIndex] = !data[i][kidIndex]
                }
                if (data[i].length == 1) {
                    data[i][0] = !data[i][0]
                }
            }
        }
        setCheckedState(data)
    }

    function closeModal() {
        setIsOpenModalNanny(false)
        setIsOpenModalPromocode(false)
        // setIsOpenModalPay(false)
    }

    const toggleClickOptionsHandler = (
        options: Array<OrderAdditionalServiceInput>
    ) => {
        calculateOrderVar({
            ...calculateOrder,
            ...{ additionalServices: options }
        })
        placeOrderVar({ ...placeOrder, ...{ additionalServices: options } })
    }
    const toggleClickPromocodeHandler = (promocode: string) => {
        calculateOrderVar({ ...calculateOrder, ...{ promoCode: promocode } })
        placeOrderVar({ ...placeOrder, ...{ promoCode: promocode } })
    }

    function cancelOrder() {
        setCheckedState(
            optionsList.map(
                (value: any) =>
                    (value =
                        value.multiple === true
                            ? new Array(profile ? profile.kids.length : 0).fill(
                                  false
                              )
                            : false)
            )
        )
        setIsOpenModalNanny(false)
    }

    return (
        <div className='options'>
            <div
                className='options__option w-row'
                onClick={() => {
                    closeModal()
                    setIsOpenModalPromocode(true)
                }}
            >
                <div className='icon w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                    <img
                        src={PromocodeSvg}
                        alt=''
                        width='40'
                        height='40'
                        className='image-7'
                    />
                </div>
                <div className='option-name w-col w-col-8 w-col-small-8 w-col-tiny-8'>
                    <div className='optiontext'>Промокод</div>
                </div>
                <div className='arrow w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                    <img
                        src={ArrowSvg}
                        loading='lazy'
                        alt=''
                        className='image-10'
                    />
                </div>
            </div>

            <div
                className='options__option w-row'
                onClick={() => {
                    closeModal()
                    setIsOpenModalNanny(true)
                }}
            >
                <div className='icon w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                    <img
                        src={NannySvg}
                        alt=''
                        width='40'
                        height='40'
                        className='image-7'
                    />
                </div>
                <div className='option-name w-col w-col-8 w-col-small-8 w-col-tiny-8'>
                    <div className='optiontext'>Помощь ребенку</div>
                </div>
                <div className='arrow w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                    <img
                        src={ArrowSvg}
                        loading='lazy'
                        alt=''
                        className='image-10'
                    />
                </div>
            </div>
            <ModalPromocode
                isOpenModalPromocode={isOpenModalPromocode}
                toggleClickPromocodeHandler={toggleClickPromocodeHandler}
                closeModal={closeModal}
                promocode={placeOrder.promoCode}
            />

            <ModalNanny
                optionsList={optionsList}
                closeModal={closeModal}
                options={calculateOrder.additionalServices}
                toggleClickOptionsHandler={toggleClickOptionsHandler}
                changeCheckedState={changeCheckedState}
                checkedState={checkedState}
                cancelOrder={cancelOrder}
                isOpenModalNanny={isOpenModalNanny}
            />

            {/* <ModalPay isOpenModalPay={isOpenModalPay} closeModal={closeModal} /> */}
        </div>
    )
}
