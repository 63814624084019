import React, { FC, useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Point } from '../../../../__generated__/types'
import { useReactiveVar } from '@apollo/client'
import { isMobileVar } from 'store/cache'
import Loader from '../../../../pages/Loader'

interface Props {
    price: number
    points: Point[]
    hide: boolean
    hideChange: () => void
    handlePlaceOrder: () => void
    carType: number
    isFetching: boolean
}

export const OrderButtonLink: FC<Props> = ({
    price,
    points,
    handlePlaceOrder,
    hide,
    hideChange,
    carType,
    isFetching
}) => {
    const { state } = useContext(AuthContext)
    const navigate = useNavigate()
    const isMobile = useReactiveVar(isMobileVar)

    const handleClickAuth = () => navigate('/auth', { replace: true })
    const handleClickPlaceOrder = () => handlePlaceOrder()
    const handleClickOpenOrderPanel = () => hideChange()

    if (!state.userToken)
        return (
            <button className='orderPanel__button' onClick={handleClickAuth}>
                Войти
            </button>
        )
    if (isMobile && !hide)
        return (
            <button
                className='orderPanel__button'
                onClick={handleClickOpenOrderPanel}
            >
                Опции заказа
            </button>
        )
    if (points.length < 2 && carType)
        return (
            <button disabled className='orderPanel__button'>
                Выберете опции заказа
            </button>
        )
    if (isFetching)
        return (
            <button disabled className='orderPanel__button'>
                <Loader />
            </button>
        )
    return (
        <button className='orderPanel__button' onClick={handleClickPlaceOrder}>
            <div className='orderPanel__order'>
                <span>Перейти к оплате</span>
                <span>{price} ₽</span>
            </div>
        </button>
    )
}
