import React from 'react'
import styles from './ForParentsPage.module.css'
import LegoCar from '../../assets/images/lego-car.png'
import { ReactComponent as Clock } from '../../assets/images/clock.svg'
import NavBar from '../../components/NavBar'
import ProfileProvider from '../../components/providers/ProfileProvider'
import Logo from '../../assets/images/logo.svg'
import WhatsUp from '../../assets/images/whatsup.svg'
import Telegram from '../../assets/images/telegram.svg'
import Mail from '../../assets/images/mail.svg'
import Instagram from '../../assets/images/instagram.svg'
import { useNavigate } from 'react-router-dom'

export const ForParentsPage = () => {
    const navigate = useNavigate()

    return (
        <ProfileProvider>
            <div className={styles.parents}>
                <NavBar />
                <div className={styles.container}>
                    <header className={styles.header}>
                        <div className={styles.header__content}>
                            <h1 className={styles.header__title}>
                                Быстрый поиск такси тогда, когда оно
                                действительно нужно
                            </h1>
                            <button
                                className={styles.button}
                                onClick={() =>
                                    navigate('/register', { replace: true })
                                }
                            >
                                Регистрация
                            </button>
                        </div>
                        <div className={styles.image}>
                            <img src={LegoCar} alt='lego-car' />
                        </div>
                    </header>

                    <section className={styles.item}>
                        <h2 className={styles.item_title}>
                            Удобство и доступность
                        </h2>
                        <div className={styles.item_carts}>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Быстрое назначение
                                    </h3>{' '}
                                    <br />
                                    Назначение такси - от 10 минут
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Доступность
                                    </h3>{' '}
                                    <br />в отдаленных районах и загородных КП
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Выбор времени
                                    </h3>{' '}
                                    <br />и графика поездок, чтобы быть
                                    уверенными, что водитель приедет вовремя
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Выбор авто
                                    </h3>{' '}
                                    <br />
                                    Выбирайте машину под задачи или по классу
                                    комфорта
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.item}>
                        <h2 className={styles.item_title}>Безопасность</h2>
                        <div className={styles.item_carts}>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        59 критериев
                                    </h3>{' '}
                                    <br />
                                    проверки водителей и авто
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Полная проверка
                                    </h3>{' '}
                                    <br />
                                    Психологическое и поведенческое тестирование
                                    водителей
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Страхование поездок
                                    </h3>{' '}
                                    <br />
                                    Страхование детей во время поездки
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart}>
                                <div className={styles.item_text}>
                                    <h3 className={styles.cart__title}>
                                        Где мои дети?
                                    </h3>{' '}
                                    <br />
                                    Отслеживание местоположения ребенка во время
                                    поездки
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.item}>
                        <h2 className={styles.item_title}>Как это работает</h2>
                        <div className={styles.item_carts}>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    Укажите когда и где нужно забрать ребенка и
                                    куда его нужно увезти, выберите опции заказа
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    Мы начинаем искать автоняню. Терпение, это
                                    может занять до 1 дня
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    Заключение договора между родителями,
                                    автоняней и агрегатором Такси Kids
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.item}>
                        <h2 className={styles.item_title}>Договор</h2>
                        <div className={styles.item_carts}>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    После оформления заказа и назначения
                                    водителя, мы формируем договор
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    Мы присылаем вам СМС с кодом подписания.
                                    Такой же код мы присылаем автоняне
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                            <div className={styles.item_cart1}>
                                <div className={styles.item_text}>
                                    Готово, договор подписан Автоняня будет у
                                    вас в назначенное время!
                                </div>
                                <div className={styles.item_indev}>
                                    <Clock height={24} width={24} />В разработке
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className={styles.button__wrapper}>
                        <button
                            className={styles.button}
                            onClick={() =>
                                navigate('/register', { replace: true })
                            }
                        >
                            Регистрация
                        </button>
                    </div>

                    <article className={styles.rates}>
                        <h2 className={styles.rates_title}>Тарифы</h2>
                        <div className={styles.rates_content}>
                            <div className={styles.rates_side}>
                                <div className={styles.side_top}>
                                    <p>от</p>
                                    <div className={styles.line}></div>
                                    <div className={styles.price}>250 р</div>
                                    <div className={styles.rates_text}>
                                        минимальная
                                        <br />
                                        стоимость заказа
                                    </div>
                                </div>
                                <div className={styles.side_bottom}>
                                    стандарт
                                </div>
                            </div>
                            <div className={styles.rates_middle}>
                                <div className={styles.side_top}>
                                    <p>от</p>
                                    <div className={styles.line}></div>
                                    <div className={styles.price}>350 р</div>
                                    <div className={styles.rates_text}>
                                        до 20 км
                                    </div>
                                    <div className={styles.rates_text}>
                                        минимальная
                                        <br />
                                        стоимость заказа
                                    </div>
                                </div>
                                <div className={styles.side_bottom}>
                                    Комфорт
                                </div>
                            </div>
                            <div className={styles.rates_side}>
                                <div className={styles.side_top}>
                                    <p>от</p>
                                    <div className={styles.line}></div>
                                    <div className={styles.price}>550 р</div>
                                    <div className={styles.rates_text}>
                                        до 20 км
                                    </div>
                                    <div className={styles.rates_text}>
                                        минимальная
                                        <br />
                                        стоимость заказа
                                    </div>
                                </div>
                                <div className={styles.side_bottom}>БИЗНЕС</div>
                            </div>
                        </div>
                    </article>

                    <footer className={styles.footer}>
                        <div className={styles.footer_top}>
                            <img src={Logo} alt='taxi-kids-logo' />
                            <div className={styles.requisites}>
                                ООО «Такси Кидс». Свидетельство о<br />
                                регистрации 1225400003091, ИНН
                                <br /> 5406819167 Адрес регистрации: г.
                                <br />
                                Новосибирск, ул. Димитрова 1/1, офис 312
                            </div>
                            <div className={styles.links}>
                                <img src={WhatsUp} alt='whats-up-logo' />
                                <img
                                    src={Telegram}
                                    width={33}
                                    alt='telegram-logo'
                                />
                                <img src={Mail} alt='mail-logo' />
                                <img src={Instagram} alt='instagram-logo' />
                            </div>
                            <div className={styles.numbers}>
                                8 800 302 05 84
                            </div>
                        </div>
                        <div className={styles.footer_bottom}>
                            На этой странице используется изображение, которое
                            имитирует модель, собранную из кирпичиков LEGO®. Мы
                            <br />
                            поддерживаем политику компании LEGO в отношение
                            авторских прав и обязаны указать, что компания LEGO®
                            <br />
                            не имеет отношения к созданию данного сайта, а так
                            же предоставляемым услугам.
                        </div>
                    </footer>
                </div>
            </div>
        </ProfileProvider>
    )
}
