import { useNavigate } from 'react-router-dom'
import TelegramQr from '../../../assets/images/telegram-qr.svg'
import AuthLayout from '../../layouts/AuthLayout'

export const TelegramBot = () => {
    const onClick = () => {
        window.open('https://t.me/taxi_kids_bot')
    }
    const navigate = useNavigate()
    return (
        <AuthLayout header={'Telegram-бот'}>
            <div className='telegram__info'>
                Подключитесь к нашему боту в Telegram, чтобы получать
                уведомления.
            </div>
            <div className='telegram__qr'>
                <img
                    className='telegram__qr-svg'
                    src={TelegramQr}
                    alt='Telegram QR'
                />
            </div>
            <button className='telegram__button' onClick={onClick}>
                Подключиться
            </button>
            <button
                className='telegram__link'
                onClick={() => navigate('/register', { replace: true })}
            >
                Уже подключен
            </button>
        </AuthLayout>
    )
}
