import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UpdateParentProfileMutationVariables = Types.Exact<{
    input: Types.UpdateParentProfileInput
}>

export type UpdateParentProfileMutation = {
    __typename: 'Mutation'
    updateParentProfile: {
        __typename: 'ParentProfile'
        dateOfIssue?: string | null | undefined
        divisionCode?: string | null | undefined
        firstName: string
        fullName?: string | null | undefined
        lastName: string
        middleName?: string | null | undefined
        passportNumbers?: string | null | undefined
        phoneNumber?: string | null | undefined
        whoIssued?: string | null | undefined
        kids: Array<{
            __typename: 'KidProfile'
            age: number
            birthCertificateNumber?: string | null | undefined
            birthday?: string | null | undefined
            dateOfIssue?: string | null | undefined
            fullName?: string | null | undefined
            insurancyPolicy?: string | null | undefined
            name: string
            phoneNumber?: string | null | undefined
            snils?: string | null | undefined
            whoIssued?: string | null | undefined
        }>
    }
}

export const UpdateParentProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateParentProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateParentProfileInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateParentProfile' },
                        name: { kind: 'Name', value: 'updateParentProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfIssue' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'divisionCode'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthCertificateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthday'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'insurancyPolicy'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'snils'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'passportNumbers'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'whoIssued' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateParentProfileMutationFn = Apollo.MutationFunction<
    UpdateParentProfileMutation,
    UpdateParentProfileMutationVariables
>

/**
 * __useUpdateParentProfileMutation__
 *
 * To run a mutation, you first call `useUpdateParentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParentProfileMutation, { data, loading, error }] = useUpdateParentProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParentProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateParentProfileMutation,
        UpdateParentProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateParentProfileMutation,
        UpdateParentProfileMutationVariables
    >(UpdateParentProfileDocument, options)
}
export type UpdateParentProfileMutationHookResult = ReturnType<
    typeof useUpdateParentProfileMutation
>
export type UpdateParentProfileMutationResult =
    Apollo.MutationResult<UpdateParentProfileMutation>
export type UpdateParentProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateParentProfileMutation,
    UpdateParentProfileMutationVariables
>
