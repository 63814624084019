import React from 'react';
import './AuthLayout.css'

export const AuthLayout = (
    {
        header,
        children
    }: {
        header: string,
        children: JSX.Element[] | JSX.Element
    }) => {

    return (
        <div className='wrapper'>
            <div className='registration'>
                <div className='registration__container'>
                    <h1 className='registration__title' dangerouslySetInnerHTML={{
                        __html: header
                    }}/>
                    {children}
                </div>
            </div>
        </div>
    );
}