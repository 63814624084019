import React from 'react'
import styles from './OrdersHistory.module.css'
import { useGetOrdersCustom } from 'hooks/useGetOrdersCustom'
import { OrdersHistoryItem } from './OrdersHistoryItem/OrdersHistoryItem'

export const OrdersHistory = () => {
    const [orders] = useGetOrdersCustom()

    if (orders.length === 0)
        return (
            <div className={styles.empty}>
                <span>У вас заказов не было</span>
            </div>
        )
    return (
        <main className={styles.main}>
            <h3 className={styles.title}>История заказов</h3>
            <section className={styles.content}>
                {orders.map((item) => (
                    <OrdersHistoryItem order={item} key={item.id} />
                ))}
            </section>
        </main>
    )
}
