type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key
          }
        : {
              type: Key
          }
}

type ShowProfileActionsTypePayload = {
    openProfileForm: boolean
    closeProfileForm: boolean
}

export type ShowProfileActionsType =
    ActionMap<ShowProfileActionsTypePayload>[keyof ActionMap<ShowProfileActionsTypePayload>]

export const profileFormReducer = (
    state: boolean,
    action: ShowProfileActionsType
) => {
    switch (action.type) {
        case 'openProfileForm':
            return true
        case 'closeProfileForm':
            return false
        default:
            return state
    }
}
