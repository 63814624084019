import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import styles from './ModalLayout.module.css'
import { createPortal } from 'react-dom'
import CloseButtonSvg from 'assets/images/close-button.svg'

const modalRootElement = document.querySelector('#modal')

interface propsType {
    children: PropsWithChildren<{}>
    open: boolean
    onClose: () => void
}

export const ModalLayout: FC<propsType> = ({ children, open, onClose }) => {
    const element = useMemo(() => document.createElement('div'), [])
    const stopPropagate = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }
    useEffect(() => {
        modalRootElement?.appendChild(element)
        return () => {
            modalRootElement?.removeChild(element)
        }
    })

    if (open) {
        return createPortal(
            <div className={styles.background} onClick={onClose}>
                <div className={styles.modal} onClick={stopPropagate}>
                    <div className={styles.content}>{children}</div>
                    <div className={styles.close} onClick={onClose}>
                        <img
                            src={CloseButtonSvg}
                            width='12.5px'
                            alt='close-button'
                        />
                    </div>
                </div>
            </div>,
            element
        )
    }
    return null
}
