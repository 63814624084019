import React, { FC, memo } from 'react'
import { CarType } from '../../../__generated__/types'
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble'

interface CarTypeIconProps {
    carType: CarType
    selectedIcon: number | null
    clickHandler: (car: CarType) => void
    index: number
}

const RubleIcon = () => {
    return <CurrencyRubleIcon sx={{ height: '20px' }} />
}
const MemoizedRubleIcon = memo(RubleIcon)

const CarTypeIcon: FC<CarTypeIconProps> = (props) => {
    const carType = props.carType
    const imageSrc = '/images/car-' + carType.code + '.svg'

    function isActive() {
        return props.selectedIcon && carType.id == props.selectedIcon
    }

    function handleClick() {
        props.clickHandler(carType)
    }

    return (
        <a
            data-w-tab={carType.code}
            className={`tab-set-class w-inline-block w-tab-link ${
                isActive() && 'w--current'
            }`}
            onClick={handleClick}
            key={carType.id}
        >
            <img alt={carType.title} src={imageSrc} className='tab-img' />
            <div className='car-class-type-text'>{carType.title}</div>
            <div className='class-price'>
                {Boolean(Number(carType.price)) && carType.price}
                {Boolean(Number(carType.price)) && <MemoizedRubleIcon />}
            </div>
        </a>
    )
}
export default React.memo(CarTypeIcon)
