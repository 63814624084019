import { useReactiveVar } from '@apollo/client'
import { isMobileVar } from 'store/cache'
import { BrowserMenu } from './BrowserMenu/BrowserMenu'
import { MobileMenu } from './MobileMenu/MobileMenu'
import './NavBar.css'
import DocumentForm from '../ProfileForm'
import React, { useContext } from 'react'
import { AppContext } from '../../store/context'

export function NavBar() {
    const isMob = useReactiveVar(isMobileVar)
    const { state, dispatch } = useContext(AppContext)
    const closeForm = () => {
        dispatch({
            type: 'closeProfileForm'
        })
    }
    if (isMob)
        return (
            <>
                <MobileMenu />
                {state.showProfileForm && (
                    <DocumentForm closeForm={closeForm} />
                )}
            </>
        )

    return (
        <>
            <BrowserMenu />
            {state.showProfileForm && <DocumentForm closeForm={closeForm} />}
        </>
    )
}
