import React, { forwardRef, HTMLProps } from 'react'
import './CustomDataInputField.css'

const CustomDataInput = (
    { onClick, value }: HTMLProps<HTMLButtonElement>,
    ref: any
) => (
    <button className='data-button-style' onClick={onClick} ref={ref}>
        {value ? value : 'Запланировать'}
    </button>
)

export const CustomDataInputField = forwardRef(CustomDataInput)
