import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { MapContext } from '../../../context/MapContext'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Box from '@mui/material/Box'
import { load } from '@2gis/mapgl'
import Dragsvg from '../../../assets/images/drag.svg'
import {Point} from '../../../__generated__/types';
import {useAddressSuggestionsQuery} from './__generated__/Address';

export const AddressInput = ({
    point,
    title
}: {
    point: Point | null
    title: string
}) => {
    const { addPointByAddress, removePoint, mapInstance, addMarker } =
        React.useContext(MapContext)
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState<readonly Point[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const { refetch } = useAddressSuggestionsQuery({
        //fetchPolicy: 'standby',
        variables: {
            input: null!
        },
        onCompleted: (data) => {
		console.log(data.searchAddress);
            // setOptions([...options, ...data.searchAddress])
            setOptions(data.searchAddress)
            setLoading(false)
        }
    })
    // console.log(options)

    return (
        <div className='rout-section-from wf-section'>
            <div
                className={
                    title === 'Куда'
                        ? 'div-route-from-underline'
                        : 'div-route-from'
                }
            >
                {title ? (
                    <div className='route_text'>
                        <img
                            src={Dragsvg}
                            width={20}
                            height={20}
                            alt='dragsvg'
                            className='route_text_img'
                        />

                        {title}
                    </div>
                ) : null}
                <div style={{ display: 'flex' }}>
                    <Autocomplete
                        style={{ width: '90%' }}
                        id='standard-basic'
                        open={open}
                        value={point}
                        onInputChange={(_, value, reason) => {
                            if (reason === 'input' && value.length > 3) {
                                setOpen(true)
                                setLoading(true)
                                refetch({ input: value })
                            }
                        }}
                        onClose={() => {
                            setOpen(false)
                        }}
                        onChange={(_, value) => {
                            value && addPointByAddress(value)
                            const arr: Array<number> = []
                            if (value)
                                if (
                                    typeof value.longitude == 'number' &&
                                    typeof value.latitude == 'number'
                                ) {
                                    arr.push(value.longitude)
                                    arr.push(value.latitude)
                                }
                            if (arr)
                                load().then((mapglAPI) => {
                                    addMarker(
                                        new mapglAPI.Marker(mapInstance, {
                                            coordinates: arr,
                                            icon: 'https://docs.2gis.com/img/dotMarker.svg'
                                        })
                                    )
                                })
                        }}
                        // isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.address || ''}
                        filterOptions={(x) => x}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                multiline
                                minRows={1}
                                maxRows={2}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    style: {
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        lineHeight: '1',
                                        paddingRight: '0'
                                    },
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color='inherit' />
                                            ) : null}
                                            {/* {params.InputProps.endAdornment} */}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            aria-label='delete'
                            size='medium'
                            onClick={() => {
                                if (point) {
                                    removePoint(point)
                                }
                            }}
                        >
                            <HighlightOffIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </div>
            </div>
        </div>
    )
}
