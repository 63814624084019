import React, { FC, useContext } from 'react'
import { ProfileContext } from '../../../context/ProfileContext'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import { AppContext } from '../../../store/context'
import CloseButtonSvg from '../../../assets/images/close-button.svg'
import { useReactiveVar } from '@apollo/client'
import { calculateOrderVar, placeOrderVar } from '../../../store/cache'
import {useDriverTypesQuery} from './__generated__/DriverTypes';
import {DriverType} from '../../../__generated__/types';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 312,
    // minHeight: 252,
    bgcolor: '#FFFFFF',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
    p: '32px 16px 24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Inter',
    fontSize: '18px',
    gap: '16px'
}

export const DriverTypeSelect: FC = () => {
    const { loading, data } = useDriverTypesQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'no-cache'
    })
    const [checkboxValue, setCheckboxValue] = React.useState(false)
    const profile = React.useContext(ProfileContext)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [isModalAuthOpen, setIsModalAuthOpen] = React.useState(false)
    const { dispatch } = useContext(AppContext)
    const navigate = useNavigate()
    const driverTypes = data?.driverTypes
    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)

    const toggleClickHandler = (driver: DriverType) => {
        clickDriveTypeHandler(Number(driver.id))
    }

    const handleOpenModal = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)

    const handleOpenModalAuth = () => setIsModalAuthOpen(true)
    const handleCloseModalAuth = () => setIsModalAuthOpen(false)

    const clickDriveTypeHandler = (count: number) => {
        calculateOrderVar({ ...calculateOrder, ...{ driverType: count } })
        placeOrderVar({ ...placeOrder, ...{ driverType: count } })
    }

    React.useEffect(() => {
        if (driverTypes) {
            checkboxValue
                ? toggleClickHandler(driverTypes[0])
                : toggleClickHandler(driverTypes[1])
        }
    }, [data, checkboxValue])

    return (
        <>
            {!loading && (
                <>
                    <div className='options__driverType'>
                        {
                            typeof driverTypes !== 'undefined' &&
                                driverTypes !== null && (
                                    <div className='options__driverTypeSwitcher'>
                                        <span className='driverTypeSwitcher__text'>
                                            Ребенок поедет без родителей
                                        </span>
                                        <label className='driverTypeSwitcher__switch'>
                                            <input
                                                type='checkbox'
                                                defaultChecked={checkboxValue}
                                                disabled={
                                                    profile
                                                        ? !profile.canCallNanny
                                                        : true
                                                }
                                                onChange={() => {
                                                    setCheckboxValue(
                                                        !checkboxValue
                                                    )
                                                    // toggleClickHandler(driverTypes.id)
                                                }}
                                            />
                                            <span
                                                className='driverTypeSwitcher__slider driverTypeSwitcher__slider_round'
                                                onClick={() => {
                                                    profile
                                                        ? !profile.canCallNanny &&
                                                          handleOpenModal()
                                                        : handleOpenModalAuth()
                                                }}
                                            ></span>
                                        </label>
                                    </div>
                                )
                            // driverTypes.map(
                            //     (item) =>
                            //         typeof item !== 'undefined' &&
                            //         item !== null && (
                            //             <DriverTypeIcon
                            //                 driverType={item}
                            //                 selectedIcon={driveTypeActive}
                            //                 clickHandler={toggleClickHandler}
                            //                 key={item.id}
                            //             />
                            //         )
                            // )
                        }
                    </div>
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={style}>
                            <div
                                className='modal__cancel-bttn'
                                onClick={handleCloseModal}
                            >
                                <img
                                    src={CloseButtonSvg}
                                    width='12.75px'
                                    alt='close-button'
                                />
                            </div>
                            <h1 className='driverTypeModal__title'>
                                Заполните профиль
                            </h1>
                            <p className='driverTypeModal__text'>
                                Для пользования услугами автоняни&nbsp;&mdash;
                                необходимо заполнить информацию о&nbsp;детях.
                                Так вы&nbsp;даете соглашение
                                на&nbsp;сопровождение вашего ребенка.
                            </p>
                            <button
                                className='driverTypeModal__button'
                                onClick={() => {
                                    dispatch({
                                        type: 'openProfileForm'
                                    })
                                    handleCloseModal()
                                }}
                            >
                                Заполнить
                            </button>
                        </Box>
                    </Modal>
                    <Modal
                        open={isModalAuthOpen}
                        onClose={handleCloseModalAuth}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={style}>
                            <div
                                className='modal__cancel-bttn'
                                onClick={handleCloseModalAuth}
                            >
                                <img
                                    src={CloseButtonSvg}
                                    width='12.75px'
                                    alt='close-button'
                                />
                            </div>
                            <h1 className='driverTypeModal__title'>
                                Для выбора этой опции - необходимо войти
                            </h1>
                            <button
                                className='driverTypeModal__button'
                                onClick={() => {
                                    navigate('/auth')
                                }}
                            >
                                Войти
                            </button>
                        </Box>
                    </Modal>
                </>
            )}
        </>
    )
}
