import { useState } from 'react'
// import Input from 'react-phone-number-input/input';
import MaskedInput from 'react-text-mask'

export const PhoneInput = ({
    error,
    handlePhoneChange
}: {
    error: string
    handlePhoneChange: (str: string) => void
}) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const handlePhoneInputChange = (value: string) => {
        const numbers = value
            .split('')
            .filter((el) => {
                return el !== ' ' && el !== '(' && el !== ')' && el !== '-'
            })
            .join('')        
        handlePhoneChange(numbers)
        setPhoneNumber(value)
    }
    return (
        <>
            <h3 className='registration__numbers'>ТЕЛЕФОН</h3>
            <MaskedInput
                mask={[
                    '+',
                    '7',
                    ' ',
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/
                ]}
                guide={false}
                name='phone'
                type='text'
                className={
                    error
                        ? 'registration__input activeError'
                        : 'registration__input'
                }
                value={phoneNumber}
                onChange={(e) => {
                    e.preventDefault
                    handlePhoneInputChange(e.target.value)
                }}
                required
            />
        </>
    )
}
