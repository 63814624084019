import React, { ChangeEvent, FC } from 'react'
import styles from './Comment.module.css'
import { useReactiveVar } from '@apollo/client'
import { calculateOrderVar, placeOrderVar } from '../../../store/cache'

export const Comment: FC = () => {
    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)

    const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value
        calculateOrderVar({ ...calculateOrder, ...{ comment: text } })
        placeOrderVar({ ...placeOrder, ...{ comment: text } })
    }

    return (
        <div className={styles.wrapper}>
            <textarea
                className={styles.textarea}
                onChange={handleChangeComment}
                placeholder='Комментарий'
                maxLength={256}
            ></textarea>
        </div>
    )
}
