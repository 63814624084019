
import { FC } from 'react'
import styles from './OrderHistoryItem.module.css'
import {Order} from '../../../__generated__/types';

interface Props {
    order: Order
}

enum TimeOfDay {
    morning = 'Утром',
    afternoon = 'Днём',
    evening = 'Вечером',
    night = 'Ночью'
}

const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря'
]
export const OrdersHistoryItem: FC<Props> = ({ order }) => {
    const time = order.dateTime?.time

    const takeTimeOfDay = (time: string | undefined) => {
        if (!time) return ''
        let newTime = Number(time.slice(0, 2))
        if (newTime > 5 && newTime < 11) return TimeOfDay.morning
        if (newTime >= 11 && newTime < 17) return TimeOfDay.afternoon
        if (newTime >= 17 && newTime < 22) return TimeOfDay.evening
        return TimeOfDay.night
    }
    const getTodayDate = () => {
        const dateObj = new Date()
        const month = dateObj.getUTCMonth() + 1
        const day = dateObj.getUTCDate()
        const year = dateObj.getUTCFullYear()

        return [Number(day), Number(month), Number(year)]
    }
    const getDate = () => {
        if (!order.dateTime) return ''
        const todayDate = getTodayDate()
        const orderDate = order.dateTime.date
            .split('.')
            .map((item) => Number(item))
        if (
            todayDate[0] === orderDate[0] &&
            todayDate[1] === orderDate[1] &&
            todayDate[2] === orderDate[2]
        )
            return 'Сегодня'
        return `${orderDate[0]} ${months[orderDate[1] - 1]}`
    }

    return (
        <article className={styles.wrapper}>
            {order.dateTime && (
                <h4 className={styles.title}>
                    {getDate()}. {takeTimeOfDay(time)}, в {time}
                </h4>
            )}

            <p className={styles.text}>
                {order.price} ₽, {order.points[0]?.address} →{' '}
                {order.points[order.points.length - 1]?.address}
            </p>

            <div className={styles.footer}>
                <div className={styles.status}>
                    <span className={styles.status__title}>Статус: </span>
                    <span>{order.status}</span>
                </div>
                <div className={styles.paid}>
                    <span>{order.paid ? 'Оплачен' : 'Не оплачен'}</span>
                </div>
            </div>
        </article>
    )
}
