import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CarTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type CarTypesQuery = {
    __typename: 'Query'
    carTypes: Array<{
        __typename: 'CarType'
        id: number
        code: string
        title: string
        price: string
        sort: number
        coefficient: number
    }>
}

export const CarTypesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CarTypesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useCarTypesQuery__
 *
 * To run a query within a React component, call `useCarTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<CarTypesQuery, CarTypesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CarTypesQuery, CarTypesQueryVariables>(
        CarTypesQueryDocument,
        options
    )
}
export function useCarTypesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CarTypesQuery,
        CarTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<CarTypesQuery, CarTypesQueryVariables>(
        CarTypesQueryDocument,
        options
    )
}
export type CarTypesQueryHookResult = ReturnType<typeof useCarTypesQuery>
export type CarTypesQueryLazyQueryHookResult = ReturnType<
    typeof useCarTypesQueryLazyQuery
>
export type CarTypesQueryQueryResult = Apollo.QueryResult<
    CarTypesQuery,
    CarTypesQueryVariables
>
