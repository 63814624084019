import React from 'react'
import { ChairsInput } from '../../../__generated__/types'
import { Booster } from './Booster'
import { Chair } from './Chair'
import { useReactiveVar } from '@apollo/client'
import { calculateOrderVar, placeOrderVar } from '../../../store/cache'

export function ChairsSelect() {
    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)
    const toggleClickBoosterHandler = (count: number) => {
        updateChairsHandler({
            ...calculateOrder.chairs,
            ...{ boosters: count }
        })
    }
    const toggleClickChairHandler = (count: number) => {
        updateChairsHandler({ ...calculateOrder.chairs, ...{ chairs: count } })
    }
    const updateChairsHandler = (chairs: ChairsInput) => {
        calculateOrderVar({ ...calculateOrder, ...{ chairs: chairs } })
        placeOrderVar({ ...placeOrder, ...{ chairs: chairs } })
    }

    return (
        <div className='div-select-chair'>
            <div
                data-current=''
                data-easing='ease'
                data-duration-in='100'
                data-duration-out='100'
                className='tabs-2 w-tabs'
            >
                <div className='set-servise-type w-tab-menu'>
                    <Booster
                        boosterCount={calculateOrder.chairs?.boosters}
                        clickBoosterHandler={toggleClickBoosterHandler}
                    />
                    <Chair
                        chairCount={calculateOrder.chairs?.chairs}
                        clickChairHandler={toggleClickChairHandler}
                    />
                </div>
            </div>
        </div>
    )
}
