import React, {useEffect} from 'react';
import {MapContext} from '../../context/MapContext';

export function RouteBuilder() {
    const {directions, points } = React.useContext(MapContext);
    
    useEffect(() => {
        if (points.length >= 2) {
            directions.clear();
            directions.carRoute({
                points: points.map((point: any) => [point.longitude, point.latitude]),
            });
        }
    }, [points])

    // markers.forEach((m) => {
    //     m.destroy();
    // });
    // resetButton.disabled = false;
    // resetButton.textContent = buttonText[1];

    // resetButton.addEventListener('click', function() {
    //     selecting = 'a';
    //     firstPoint = undefined;
    //     secondPoint = undefined;
    //     directions.clear();
    //     this.disabled = true;
    //     this.textContent = buttonText[0];
    // });


return (
    <></>
);
}