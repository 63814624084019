import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import './ProfileMenu.css'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as HintLogo } from '../../assets/images/yellowhint.svg'
import { Tooltip } from '@mui/material'
import { SupportModal } from '../SupportModal'
import { AppContext } from '../../store/context'
import { ProfileContext } from '../../context/ProfileContext'
import { useReactiveVar } from '@apollo/client'
import { showOrdersHistoryVar } from '../../store/cache'
import { ModalLayout } from '../layouts/ModalLayout/ModalLayout'
import { OrdersHistory } from '../OrdersHistory'
// import { AppContext } from '../../store/context'

export const ProfileMenu = () => {
    const [showSupport, setShowSupport] = React.useState(false)
    const auth = useContext(AuthContext)
    const { dispatch } = useContext(AppContext)
    const navigate = useNavigate()
    const profile = useContext(ProfileContext)
    const showOrdersHistory = useReactiveVar(showOrdersHistoryVar)

    const logout = () => {
        localStorage.clear()
        window.location.href = '/'
    }

    const openForm = () => {
        dispatch({
            type: 'openProfileForm'
        })
    }
    const openSupport = () => {
        setShowSupport(true)
    }
    const closeSupport = () => {
        setShowSupport(false)
    }
    const changeShowOrdersHistory = () =>
        showOrdersHistoryVar(!showOrdersHistory)

    return (
        <div className='profile-box'>
            {auth.state.userToken ? (
                <>
                    <div className='profile-box__upper'>
                        <div className='profile-box__name'>
                            {profile && profile.firstName}
                        </div>
                        <div className='profile-box__name'>
                            {profile && profile.lastName}
                        </div>

                        <div className='profile-box__tel'>
                            {profile && profile.phoneNumber}
                        </div>
                    </div>
                    <div className='profile-box__lower'>
                        <div className='profile-box__data' onClick={openForm}>
                            ЛИЧНЫЕ ДАННЫЕ
                            {profile == null && (
                                <Tooltip
                                    className='asd'
                                    title='Заполните профиль для оформления заказа.'
                                    placement='top'
                                >
                                    <HintLogo className='title-input_hint' />
                                </Tooltip>
                            )}
                        </div>
                        <div
                            className='profile-box__data'
                            onClick={openSupport}
                        >
                            ПОДДЕРЖКА
                        </div>
                        <div
                            className='profile-box__data'
                            onClick={changeShowOrdersHistory}
                        >
                            ИСТОРИЯ ЗАКАЗОВ
                        </div>
                        <div className='profile-box__info'>
                            Сервис заказа автонянь <br /> и детского такси
                        </div>
                        <div className='profile-box__logout' onClick={logout}>
                            Выйти
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='profile-box__upper'>
                        <div className='profile-box__name'>
                            Для оформления заказа вам нужно войти
                        </div>

                        <div className='profile-box__tel'>
                            {profile && profile.phoneNumber}
                        </div>
                    </div>
                    <div className='profile-box__lower'>
                        <div
                            className='profile-box__data'
                            onClick={openSupport}
                        >
                            ПОДДЕРЖКА
                        </div>
                        <div className='profile-box__info'>
                            Сервис заказа автонянь <br /> и детского такси
                        </div>
                        <div
                            className='profile-box__logout'
                            onClick={() => navigate('/auth', { replace: true })}
                        >
                            Войти
                        </div>
                    </div>
                </>
            )}

            {/*Modals*/}

            {
                <ModalLayout
                    open={showOrdersHistory}
                    onClose={changeShowOrdersHistory}
                >
                    <OrdersHistory />
                </ModalLayout>
            }
            <SupportModal showSupport={showSupport} closeModal={closeSupport} />
        </div>
    )
}
