import * as React from 'react'
import { AuthContext } from '../../context/AuthContext'
import CodeCheck from '../../components/screens/CodeCheck'
import EnterPhone from '../../components/screens/EnterPhone'
import UpdatePassword from '../../components/UpdatePassword'

export const Recovery = () => {
    const [otp, setOtp] = React.useState<string>(null!)
    const [phoneNumber, setPhoneNumber] = React.useState<string>(null!)
    const { resetPassword } = React.useContext(AuthContext)

    const updatePasswordData = (password: string) => {
        resetPassword({
            phoneNumber: phoneNumber,
            otp: otp,
            password: password
        })
    }

    return otp ? (
        <UpdatePassword handlePassword={updatePasswordData} />
    ) : phoneNumber ? (
        <CodeCheck phoneNumber={phoneNumber} handleCodeValidated={setOtp} />
    ) : (
        <EnterPhone handlePhoneNumber={setPhoneNumber} />
    )
}
