import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CompletePointGeoMutationVariables = Types.Exact<{
    input: Types.PointInput
}>

export type CompletePointGeoMutation = {
    __typename: 'Mutation'
    fillPointGeo: {
        __typename: 'Point'
        sort: number
        address?: string | null | undefined
        latitude?: number | null | undefined
        longitude?: number | null | undefined
    }
}

export const CompletePointGeoDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CompletePointGeo' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PointInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fillPointGeo' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'point' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CompletePointGeoMutationFn = Apollo.MutationFunction<
    CompletePointGeoMutation,
    CompletePointGeoMutationVariables
>

/**
 * __useCompletePointGeoMutation__
 *
 * To run a mutation, you first call `useCompletePointGeoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePointGeoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePointGeoMutation, { data, loading, error }] = useCompletePointGeoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePointGeoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompletePointGeoMutation,
        CompletePointGeoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CompletePointGeoMutation,
        CompletePointGeoMutationVariables
    >(CompletePointGeoDocument, options)
}
export type CompletePointGeoMutationHookResult = ReturnType<
    typeof useCompletePointGeoMutation
>
export type CompletePointGeoMutationResult =
    Apollo.MutationResult<CompletePointGeoMutation>
export type CompletePointGeoMutationOptions = Apollo.BaseMutationOptions<
    CompletePointGeoMutation,
    CompletePointGeoMutationVariables
>
