import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AddressSuggestionsQueryVariables = Types.Exact<{
    input: Types.Scalars['String']
}>

export type AddressSuggestionsQuery = {
    __typename: 'Query'
    searchAddress: Array<{
        __typename: 'Point'
        sort: number
        address?: string | null | undefined
        latitude?: number | null | undefined
        longitude?: number | null | undefined
    }>
}

export const AddressSuggestionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AddressSuggestions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchAddress' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'query' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useAddressSuggestionsQuery__
 *
 * To run a query within a React component, call `useAddressSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressSuggestionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        AddressSuggestionsQuery,
        AddressSuggestionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        AddressSuggestionsQuery,
        AddressSuggestionsQueryVariables
    >(AddressSuggestionsDocument, options)
}
export function useAddressSuggestionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AddressSuggestionsQuery,
        AddressSuggestionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        AddressSuggestionsQuery,
        AddressSuggestionsQueryVariables
    >(AddressSuggestionsDocument, options)
}
export type AddressSuggestionsQueryHookResult = ReturnType<
    typeof useAddressSuggestionsQuery
>
export type AddressSuggestionsLazyQueryHookResult = ReturnType<
    typeof useAddressSuggestionsLazyQuery
>
export type AddressSuggestionsQueryResult = Apollo.QueryResult<
    AddressSuggestionsQuery,
    AddressSuggestionsQueryVariables
>
