import React, { useState } from 'react';

export function Chair({ chairCount, clickChairHandler }: any) {
    const [chairs, setChairs] = useState(0)
    function handleClick(count: any) {
        clickChairHandler(count)
        setChairs(count)
    }

    return (
        <a
            data-w-tab='kidstaxi'
            className='chair-type w-inline-block w-tab-link'
        >
            <img
                alt='no_image'
                src='https://uploads-ssl.webflow.com/61f36156ea53421f11e2920d/61f3d2e51bdcb712435554f3_2022-01-08_17-32-22%201.svg'
                className='image-12 image-19'
            />
            <div className='car-class-type-text'>Кресло</div>
            <div className='div-block-16'/>
                <div className='div-quantity'>
                    <div className='quantity w-row'>
                        <div
                            className='column-30 w-col w-col-4 w-col-small-4 w-col-tiny-4'
                            onClick={() => chairs >= 1 && handleClick(chairs -1)}
                        >
                            <div className='text-block-8'>-</div>
                        </div>
                            <div className='column-28 w-col w-col-4 w-col-small-4 w-col-tiny-4'>
                        <div className='text-block-8'>{chairs}</div>
                    </div>
                    <div
                        className='column-29 w-col w-col-4 w-col-small-4 w-col-tiny-4'
                        onClick={() => chairs < 6 && handleClick(chairs + 1)}
                    >
                        <div className='text-block-8'>+</div>
                    </div>
                </div>
            </div>
            <div className='div-block-16'/>
        </a>
    );
}
