import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DriverTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type DriverTypesQuery = {
    __typename: 'Query'
    driverTypes: Array<{
        __typename: 'DriverType'
        id: number
        code: string
        title: string
        sort: number
    }>
}

export const DriverTypesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DriverTypesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useDriverTypesQuery__
 *
 * To run a query within a React component, call `useDriverTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriverTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        DriverTypesQuery,
        DriverTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<DriverTypesQuery, DriverTypesQueryVariables>(
        DriverTypesQueryDocument,
        options
    )
}
export function useDriverTypesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DriverTypesQuery,
        DriverTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<DriverTypesQuery, DriverTypesQueryVariables>(
        DriverTypesQueryDocument,
        options
    )
}
export type DriverTypesQueryHookResult = ReturnType<typeof useDriverTypesQuery>
export type DriverTypesQueryLazyQueryHookResult = ReturnType<
    typeof useDriverTypesQueryLazyQuery
>
export type DriverTypesQueryQueryResult = Apollo.QueryResult<
    DriverTypesQuery,
    DriverTypesQueryVariables
>
