import { useEffect, useState } from 'react'

export function useDetectSize() {
    const [windowDimension, detectHW] = useState<{
        winWidth: number
        winHeight: number
    }>({
        winWidth: 0,
        winHeight: 0
    })

    useEffect(() => {
        detectHW({
            winHeight: window.innerHeight,
            winWidth: window.innerWidth
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight
        })
    }
    return [windowDimension]
}
