import './ProfileForm.css'
import CloseButtonSvg from '../../assets/images/close-button.svg'
import PlusSvg from '../../assets/images/plus.svg'
import { DefaultValues, useFieldArray, useForm } from 'react-hook-form'
import { Tooltip } from '@mui/material'
import { ReactComponent as HintLogo } from '../../assets/images/hint.svg'
import InputMask from 'react-input-mask'
import { ProfileContext } from '../../context/ProfileContext'
import { useContext, useEffect, useState } from 'react'
import {CreateParentProfileInput, KidProfile} from '../../__generated__/types';
import {useUpdateParentProfileMutation} from './__generated__/UpdateParentProfile';

const defaultValues: DefaultValues<CreateParentProfileInput> = {
    kids: [{}]
}
const datePattern =
    /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/i

export const ProfileForm = ({ closeForm }: { closeForm: () => void }) => {
    const profile = useContext(ProfileContext)
    const [mutationSent, setMutationSent] = useState(false)
    const [isformChanged, setIsFormChanged] = useState(false)

    const getProfileData = () => {
        if (profile) {
            // Удаляю ненужные свойства у обьекта xD
            const { __typename, id, kids, canCallNanny, ...newProfile } =
                profile
            const newKids = profile.kids.map(
                ({__typename, id, ...rest }: KidProfile) => rest
            )
            return { ...newProfile, ...{ kids: newKids } }
        } else return defaultValues
    }

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty }
    } = useForm<CreateParentProfileInput>({
        defaultValues: getProfileData()
    })
    const { fields, append } = useFieldArray({
        control,
        name: 'kids'
    })
    const [updateParentProfile] = useUpdateParentProfileMutation()

    useEffect(() => {
        isDirty ? setIsFormChanged(true) : setIsFormChanged(false)
    }, [isDirty])

    function getAge(dateString: Date) {
        let today = new Date()
        let birthDate = new Date(dateString)
        let age = today.getFullYear() - birthDate.getFullYear()
        let m = today.getMonth() - birthDate.getMonth()
        let d = today.getDay() - birthDate.getDay()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        if (age === 0) {
            m = 12 + m
            if (d < 0 || (d === 0 && today.getDate() < birthDate.getDate())) {
                m--
            }
        }
        return age
    }

    const onClickSubmit = (data: CreateParentProfileInput) => {
        data.fullName
            ? (data.lastName = data.fullName?.split(' ')[0])
            : (data.firstName = '')
        data.fullName
            ? (data.firstName = data.fullName?.split(' ')[1])
            : (data.firstName = '')

        data.kids.forEach((item) => {
            if (item?.birthday) {
                let d = item.birthday.split('.').reverse().join('-')
                let birthDate = new Date(d)
                item.age = getAge(birthDate)
            }
            if (item?.fullName) {
                item.name = item.fullName.split(' ')[1]
            }
        })

        isformChanged
            ? mutationSent
                ? closeForm()
                : updateParentProfile({
                      variables: { input: data },
                      refetchQueries: ['getProfile'],
                      onCompleted: () => {
                          setMutationSent(true)
                      }
                  })
            : closeForm()
    }

    return (
        <div className='doc-form'>
            <div className='doc-form__cancel-container' onClick={closeForm}>
                <img src={CloseButtonSvg} width='12.5px' alt='close-button' />
            </div>
            <div className='doc-form__title'>
                Личные данные для договора сопровождения
            </div>

            <form
                className='doc-form__content'
                onSubmit={handleSubmit((data) => {
                    onClickSubmit(data)
                })}
            >
                <div className='doc-form__title-input'>Ваши данные</div>
                <div className='doc-form__input-container'>
                    <div className='doc-form__input-box'>ФИО</div>
                    <input
                        className={
                            errors.fullName
                                ? 'doc-form__input doc-form__input_validation'
                                : 'doc-form__input'
                        }
                        type='text'
                        aria-invalid={errors.fullName ? 'true' : 'false'}
                        {...register(`fullName`, {
                            pattern:
                                /^[а-я]([-']?[а-я]+)*( [а-я]([-']?[а-я]+)*)+$/i
                        })}
                        required
                    />
                </div>
                <div className='doc-form__title-input'>Паспорт РФ</div>
                <div className='doc-form__input-container'>
                    <div className='doc-form__input-box'>Серия и номер</div>
                    <InputMask
                        mask='9999 999999'
                        maskPlaceholder=''
                        className={
                            errors.passportNumbers
                                ? 'doc-form__input doc-form__input_validation'
                                : 'doc-form__input'
                        }
                        type='text'
                        required
                        aria-invalid={errors.passportNumbers ? 'true' : 'false'}
                        {...register('passportNumbers', {
                            pattern: /^([0-9]{4}\s{1}[0-9]{6})?$/i
                        })}
                    />
                </div>
                <div className='doc-form__input-container'>
                    <div className='doc-form__input-box'>Кем выдан</div>
                    <input
                        className={
                            errors.whoIssued
                                ? 'doc-form__input doc-form__input_validation'
                                : 'doc-form__input'
                        }
                        type='text'
                        required
                        aria-invalid={errors.whoIssued ? 'true' : 'false'}
                        {...register(`whoIssued`)}
                    />
                </div>
                <div className='doc-form__input-container-2'>
                    <div className='doc-form__input-container-item'>
                        <div className='doc-form__input-box'>
                            Код подразделения
                        </div>
                        <InputMask
                            mask='999-999'
                            maskPlaceholder={null}
                            className={
                                errors.divisionCode
                                    ? 'doc-form__input doc-form__input_validation'
                                    : 'doc-form__input'
                            }
                            type='text'
                            required
                            aria-invalid={
                                errors.divisionCode ? 'true' : 'false'
                            }
                            {...register(`divisionCode`, {
                                pattern: /^([0-9]{3}[-]{1}[0-9]{3})?$/i
                            })}
                        />
                    </div>
                    <div className='doc-form__input-container-item'>
                        <div className='doc-form__input-box'>Дата выдачи</div>
                        <InputMask
                            mask='99.99.9999'
                            maskPlaceholder={null}
                            className={
                                errors.dateOfIssue
                                    ? 'doc-form__input doc-form__input_validation'
                                    : 'doc-form__input'
                            }
                            type='text'
                            required
                            aria-invalid={errors.dateOfIssue ? 'true' : 'false'}
                            {...register(`dateOfIssue`, {
                                pattern: datePattern
                            })}
                        />
                    </div>
                </div>
                <div className='doc-form__title-input'>
                    <div>Данные ребенка</div>
                    <Tooltip
                        className='tooltip'
                        title='Данные ребенка нужны для оформления договора-оферты, включая согласие на сопровождение. А также на случай ДТП или несчастного случая для оперативного вызова скорой помощи сопровождающим лицом.'
                        placement='top-start'
                    >
                        <HintLogo className='title-input_hint' />
                    </Tooltip>
                </div>

                {/* Array ChildForm */}

                {fields.map((field, index) => {
                    return (
                        <div className='child-form' key={field.id}>
                            {' '}
                            <div className='doc-form__input-container'>
                                <div className='doc-form__input-box'>ФИО</div>
                                <input
                                    className='doc-form__input'
                                    type='text'
                                    required
                                    {...register(`kids.${index}.fullName`, {
                                        pattern:
                                            /^[а-я]([-']?[а-я]+)*( [а-я]([-']?[а-я]+)*)+$/i
                                    })}
                                />
                            </div>
                            <div className='doc-form__line'></div>
                            <div className='doc-form__input-container-2'>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        Номер телефона
                                    </div>
                                    <InputMask
                                        mask='+7 (999) 999-99-99'
                                        maskPlaceholder={null}
                                        className='doc-form__input'
                                        type='text'
                                        required
                                        {...register(
                                            `kids.${index}.phoneNumber`,
                                            {
                                                pattern:
                                                    /^([+]{1}[7]{1}\s{1}[(]{1}[0-9]{3}[)]{1}\s{1}[0-9]{3}[-]{1}[0-9]{2}[-]{1}[0-9]{2})?$/i
                                            }
                                        )}
                                    />
                                </div>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        Дата рождения
                                    </div>
                                    <InputMask
                                        mask='99.99.9999'
                                        maskPlaceholder={null}
                                        className='doc-form__input'
                                        type='text'
                                        required
                                        {...register(`kids.${index}.birthday`, {
                                            pattern: datePattern
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='doc-form__line'></div>
                            <div className='doc-form__input-container-2'>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        Номер свидетельства о рождении
                                    </div>
                                    <input
                                        className='doc-form__input'
                                        type='text'
                                        required
                                        {...register(
                                            `kids.${index}.birthCertificateNumber`
                                        )}
                                    />
                                </div>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        Дата выдачи
                                    </div>
                                    <InputMask
                                        mask='99.99.9999'
                                        maskPlaceholder={null}
                                        className='doc-form__input'
                                        required
                                        type='text'
                                        {...register(
                                            `kids.${index}.dateOfIssue`,
                                            {
                                                pattern: datePattern
                                            }
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='doc-form__input-container'>
                                <div className='doc-form__input-box'>
                                    Кем выдано
                                </div>
                                <input
                                    className='doc-form__input'
                                    type='text'
                                    required
                                    {...register(`kids.${index}.whoIssued`)}
                                />
                            </div>
                            <div className='doc-form__line'></div>
                            <div className='doc-form__input-container-2'>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        Полис ОМС
                                    </div>
                                    <InputMask
                                        mask='9999999999999999'
                                        maskPlaceholder={null}
                                        className='doc-form__input'
                                        type='text'
                                        required
                                        {...register(
                                            `kids.${index}.insurancyPolicy`,
                                            {
                                                pattern: /^([0-9]{16})?$/i
                                            }
                                        )}
                                    />
                                </div>
                                <div className='doc-form__input-container-item'>
                                    <div className='doc-form__input-box'>
                                        СНИЛС
                                    </div>
                                    <InputMask
                                        mask='999-999-999-99'
                                        maskPlaceholder={null}
                                        className='doc-form__input'
                                        type='text'
                                        required
                                        {...register(`kids.${index}.snils`, {
                                            pattern:
                                                /^([0-9]{3}[-]{1}[0-9]{3}[-]{1}[0-9]{3}[-]{1}[0-9]{2})?$/i
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}

                <div className='doc-form__add-child' onClick={() => append(null)}>
                    <img src={PlusSvg} width={24} height={24} alt='plus' />
                    <div className='doc-form__add-child-text'>
                        Добавить ребенка
                    </div>
                </div>
                <div className='doc-form__confident'>
                    Нажимая на кнопку сохранить, вы соглашаетесь на{' '}
                    <span
                        onClick={() =>
                            window.open(
                                'https://legal.taxikids.ru/licenseagreement'
                            )
                        }
                    >
                        обработку персональных данных
                    </span>{' '}
                    и принимаете
                    <span
                        onClick={() =>
                            window.open('https://legal.taxikids.ru/personaldata')
                        }
                    >
                        {' '}
                        условия оферты
                    </span>
                </div>

                <button className='doc-form__button' type='submit'>
                    {isformChanged
                        ? mutationSent
                            ? 'Готово'
                            : 'Сохранить'
                        : 'Готово'}
                </button>
            </form>
        </div>
    )
}
