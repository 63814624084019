import React, { FC, useEffect, useState } from 'react'
import {
    OrderFragment,
    useCalculateOrderQuery, useGetPaymentLinkLazyQuery,
usePlaceOrderMutation
} from '../__generated__/order'
import { Point } from '../../../__generated__/types'
import { useReactiveVar } from '@apollo/client'
import {
    calculateOrderVar,
    isOpenModalPayVar,
    placeOrderVar
} from 'store/cache'
import { ProfileContext } from 'context/ProfileContext'
import { ModalPay } from '../ModalPay/ModalPay'
import { ModalLayout } from 'components/layouts/ModalLayout/ModalLayout'
import { OrderButtonLink } from './OrderButtonLink/OrderButtonLink'

interface Props {
    distance: number
    points: Point[]
    hide: boolean
    hideChange: () => void
}

export const OrderButton: FC<Props> = ({
    distance,
    points,
    hide,
    hideChange
}) => {
    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)
    const isOpenPayModal = useReactiveVar(isOpenModalPayVar)

    // Modals State
    const [price, updatePrice] = useState<number>(null!)
    const [openModal, setOpenModal] = useState(false)
    const [isPaymentLink, setIsPaymentLink] = useState(true)
    const [isFetching, setIsFetching] = useState(false)
    const profile = React.useContext(ProfileContext)

    // Queries and Mutations
    const [usePlaceOrder, { data }] = usePlaceOrderMutation()
    const [getPaymentLink, paymentLink] = useGetPaymentLinkLazyQuery()
    const { refetch } = useCalculateOrderQuery({
        fetchPolicy: 'standby',
        variables: {
            input: calculateOrder
        },
        onCompleted: (data) => updateCalculatedFields(data.calculate)
    })

    useEffect(() => {
        // выводим цену заказа
        refetch({ input: { ...calculateOrder, ...{ range: distance } } })
        // изменяем поле range в placeOrder
        placeOrderVar({ ...placeOrder, ...{ range: distance } })
    }, [distance, calculateOrder])

    // Добаваляет в placeOrder координаты адресов и меняет pointsLength
    useEffect(() => {
        const newPoints = points.map((item) => {
            delete item.__typename
            return item
        })
        placeOrderVar({
            ...placeOrder,
            ...{ points: newPoints }
        })
    }, [points.length])

    // Отправляем заказ и получаем ссылку на оплату
    useEffect(() => {
        data?.placeOrder.id &&
            getPaymentLink({ variables: { orderId: data.placeOrder.id } })
    }, [data])

    // Получаем ссылку оплаты
    useEffect(() => {
        if (paymentLink.data && isPaymentLink) {
            setIsFetching(false)
            isOpenModalPayVar(true)
            setIsPaymentLink(false)
        }
    }, [paymentLink])

    const handlePlaceOrder = async () => {
        if (profile) {
            setIsFetching(true)
            await usePlaceOrder({
                variables: { input: placeOrder }
            })
        } else handleOpenModal()
    }

    const updateCalculatedFields = (order: OrderFragment) => {
        calculateOrderVar({ ...calculateOrder, ...{ range: distance } })
        updatePrice(order.finalPrice)
    }
    const handleNavigatePaymentLink = () => {
        paymentLink.data && window.open(paymentLink.data.paymentLink)
    }

    const handleClosePayModal = () => isOpenModalPayVar(false)
    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)

    return (
        <>
            <OrderButtonLink
                price={price}
                carType={placeOrder.carType}
                points={points}
                handlePlaceOrder={handlePlaceOrder}
                hide={hide}
                hideChange={hideChange}
                isFetching={isFetching}
            />

            <ModalPay
                open={isOpenPayModal}
                handleClose={handleClosePayModal}
                handleNavigate={handleNavigatePaymentLink}
            />
            <ModalLayout open={openModal} onClose={handleCloseModal}>
                <h3>Заполните профиль</h3>
            </ModalLayout>
        </>
    )
}
