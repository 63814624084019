import { useEffect, useState } from 'react'
import {Order} from '../__generated__/types';
import {useGetOrdersQuery} from '../components/OrdersHistory/__generated__/getOrders';

export const useGetOrdersCustom = () => {
    const [orders, setOrders] = useState<Order[]>([])
    const { data, loading } = useGetOrdersQuery()

    useEffect(() => {
        if (data && !loading) setOrders(data.orders.items)
    }, [data, loading])
    return [orders]
}
