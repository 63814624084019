import React, { memo, useState } from 'react'
import CarTypeIcon from './CarTypeIcon'
import { useCarTypes } from 'hooks/useCarTypes'
import { useReactiveVar } from '@apollo/client'
import { calculateOrderVar, placeOrderVar } from 'store/cache'
import {CarType} from '../../../__generated__/types';

const CarTypeSelect = () => {
    const [carTypes] = useCarTypes()
    const [carTypeActive, setCarTypeActive] = useState<number>(1)
    const placeOrder = useReactiveVar(placeOrderVar)
    const calculateOrder = useReactiveVar(calculateOrderVar)

    const toggleClickHandler = (car: CarType) => {
        clickCarTypeHandler(car.id)
        setCarTypeActive(car.id)
    }
    const clickCarTypeHandler = (count: number) => {
        calculateOrderVar({ ...calculateOrder, ...{ carType: count } })
        placeOrderVar({ ...placeOrder, ...{ carType: count } })
    }

    return (
        <>
            <div className='div-set-class-type'>
                {carTypes.map((item, index) => (
                    <CarTypeIcon
                        carType={item}
                        selectedIcon={carTypeActive}
                        clickHandler={toggleClickHandler}
                        key={item.id}
                        index={index}
                    />
                ))}
            </div>
        </>
    )
}

export const MemoizedCarTypeSelect = memo(CarTypeSelect)
