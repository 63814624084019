import { useEffect, useState } from 'react'
import { CarType } from '../__generated__/types'
import { useCarTypesQuery } from '../components/OrderPanel/CarTypeSelect/__generated__/CarTypes'

export function useCarTypes() {
    const [carTypes, setCartypes] = useState<CarType[]>([])
    const { data, loading } = useCarTypesQuery()

    useEffect(() => {
        if (data && !loading) setCartypes(data.carTypes)
    }, [data, loading])

    return [carTypes]
}
