import React, { useState } from 'react';

export function Booster({ boosterCount, clickBoosterHandler }: any) {
    const [boosters, setBoosters] = useState(0)
    
    function handleClick(count: any) {
        clickBoosterHandler(count)
        setBoosters(count)
    }
    
    return (
        <a 
            data-w-tab='Autonanny'
            className='chair-type w-inline-block w-tab-link'
        >
            <img
                alt='no_image'
                src='https://uploads-ssl.webflow.com/61f36156ea53421f11e2920d/61f3d28ed6b28360f5dd18a1_2022-01-08_17-31-29%201.svg'
                className='image-11' 
            />
            <div className='car-class-type-text'>Бустер</div>
            <div className='div-block-16'></div>
            <div className='div-quantity'>
                <div className='quantity w-row'>
                    <div
                        className='column-30 w-col w-col-4 w-col-small-4 w-col-tiny-4'
                        onClick={() => boosters >= 1 && handleClick(boosters -1)}
                    >
                        <div className='text-block-8'>-</div>
                    </div>
                    <div className='column-28 w-col w-col-4 w-col-small-4 w-col-tiny-4'>
                        <div className='text-block-8'>{boosters}</div>
                    </div>
                    <div
                        className='column-29 w-col w-col-4 w-col-small-4 w-col-tiny-4'
                        onClick={() => boosters < 6 && handleClick(boosters + 1)}
                    >
                        <div className='text-block-8'>+</div>
                    </div>
                </div>
            </div>
            <div className='div-block-16'></div>
        </a>
    );
}
