import { makeVar } from '@apollo/client'
import {
    CalculateOrderInput,
    PlaceOrderInput
} from '../__generated__/types'

export const isMobileVar = makeVar<boolean>(false)

export const showOrdersHistoryVar = makeVar<boolean>(false)

// Для оформления заказа
export const placeOrderVar = makeVar<PlaceOrderInput>({
    additionalServices: [],
    carType: 1,
    chairs: { boosters: 0, chairs: 0 },
    comment: null,
    dateTime: null,
    driverType: 0,
    points: [],
    promoCode: null,
    range: 0
})
export const isOpenModalPayVar = makeVar<boolean>(false)
// Для подсчёта суммы заказа
export const calculateOrderVar = makeVar<CalculateOrderInput>({
    carType: 1
})
