import CloseButtonSvg from '../../../../assets/images/close-button.svg'
import {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react'
import { useCheckPromoCodeLazyQuery } from '../__generated__/services'
import { InputMaybe } from '../../../../__generated__/types'
import { AuthContext } from '../../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { ModalLayout } from '../../../layouts/ModalLayout/ModalLayout'

export const ModalPromocode = ({
    closeModal,
    toggleClickPromocodeHandler,
    promocode,
    isOpenModalPromocode
}: {
    closeModal: () => void
    toggleClickPromocodeHandler: (promocode: string) => void
    promocode: InputMaybe<string> | undefined
    isOpenModalPromocode: boolean
}) => {
    const [isPromocodeActivated, setIsPromocodeActivated] = useState(false)
    const [status, setStatus] = useState(true)
    const [promoCode, setPromoCode] = useState('')
    const inputEl = useRef<HTMLInputElement>(null)
    const { state } = useContext(AuthContext)
    const navigate = useNavigate()
    const [getPromoCode, { data, loading }] = useCheckPromoCodeLazyQuery({
        variables: {
            promoCode: promoCode
        }
    })

    useEffect(() => {
        if (promocode) {
            setIsPromocodeActivated(true)
            setPromoCode(promocode)
        }

        if (!loading && data && promoCode) {
            setStatus(data.checkPromoCode)
            if (data.checkPromoCode) {
                toggleClickPromocodeHandler(promoCode)
                setIsPromocodeActivated(true)
            } else {
                setIsPromocodeActivated(false)
            }
        }
    }, [loading, data, promoCode])

    useEffect(() => {
        inputEl.current?.focus()
    })

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (isPromocodeActivated) closeModal()
        getPromoCode()
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setPromoCode(e.target.value)
    }
    const handleNavigate = () => {
        navigate('/login', { replace: true })
    }

    const modalTitleClassName = isPromocodeActivated
        ? 'Промокод активирован'
        : 'Активировать промокод'
    const inputClassName = status
        ? 'promocode__input'
        : 'promocode__input promocode__error'

    return (
        <ModalLayout open={isOpenModalPromocode} onClose={closeModal}>
            <div className='modal__promocode'>
                <div className='modal__cancel-button' onClick={closeModal}>
                    <img src={CloseButtonSvg} alt='close-button' />
                </div>
                <div className='modal__container'>
                    <h4 className='modal__title'>{modalTitleClassName}</h4>
                    <p className='modal__text'>Скидка 10% на первую поездку</p>
                    <form onSubmit={onSubmit}>
                        <div className='promocode__box'>
                            <input
                                className={inputClassName}
                                value={promoCode}
                                onChange={handleChange}
                                readOnly={isPromocodeActivated ? true : false}
                                ref={inputEl}
                            />
                            {!status ? (
                                <div className='promocode__message'>
                                    Промокод недействителен
                                </div>
                            ) : null}
                        </div>

                        <div className='modal__button'>
                            {state.userToken ? (
                                <button
                                    className='modal__button_a'
                                    type='submit'
                                >
                                    {isPromocodeActivated
                                        ? 'Готово'
                                        : 'Активировать'}
                                </button>
                            ) : (
                                <button
                                    className='modal__button_a'
                                    onClick={handleNavigate}
                                >
                                    Войти
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </ModalLayout>
    )
}
