import * as Types from '../../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetProfileQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetProfileQuery = {
    __typename: 'Query'
    profile?:
        | {
              __typename: 'ParentProfile'
              id: number
              firstName: string
              fullName?: string | null | undefined
              canCallNanny: boolean
              dateOfIssue?: string | null | undefined
              divisionCode?: string | null | undefined
              passportNumbers?: string | null | undefined
              phoneNumber?: string | null | undefined
              whoIssued?: string | null | undefined
              lastName: string
              middleName?: string | null | undefined
              telegramAccount?: string | null | undefined
              kids: Array<{
                  __typename: 'KidProfile'
                  id: number
                  fullName?: string | null | undefined
                  birthCertificateNumber?: string | null | undefined
                  birthday?: string | null | undefined
                  dateOfIssue?: string | null | undefined
                  insurancyPolicy?: string | null | undefined
                  phoneNumber?: string | null | undefined
                  snils?: string | null | undefined
                  whoIssued?: string | null | undefined
                  name: string
                  age: number
              }>
          }
        | null
        | undefined
}

export const GetProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getProfile' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'canCallNanny'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfIssue' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'divisionCode'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'passportNumbers'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'whoIssued' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthCertificateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthday'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'insurancyPolicy'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'snils'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export function useGetProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<
    typeof useGetProfileLazyQuery
>
export type GetProfileQueryResult = Apollo.QueryResult<
    GetProfileQuery,
    GetProfileQueryVariables
>
