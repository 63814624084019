import Logo from 'assets/images/logo.svg'
import Location from 'assets/images/location.svg'
import PhotoUser from 'assets/images/photo-user.svg'
import PhotoUserNone from 'assets/images/user-photo-none.svg'
import { ProfileMenu } from '../../ProfileMenu'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../context/ProfileContext'
import CustomLink from 'components/UI/Link/CustomLink'

export const BrowserMenu: React.FC = () => {
    const [showLk, setShowLk] = React.useState(false)
    const profile = useContext(ProfileContext)

    const handleChangeStateShowLk = () => setShowLk(!showLk)

    return (
        <nav className='navigationbar'>
            <div className='navigationbar__container'>
                <div className='navigationbar__left'>
                    <CustomLink to='/' className='navigationbar__logo'>
                        <img src={Logo} alt='logo-taxi-kids' />
                    </CustomLink>
                    <div className='navigationbar__location'>
                        <img
                            className='location__img'
                            src={Location}
                            alt='location'
                        />
                        <div className='location__city'>Новосибирск</div>
                    </div>

                    <a className='navigation__numbers' href='tel:+78003005657'>
                        8 800 300 56 57
                    </a>
                    <ul className='navigationbar__menu'>
                        <li className='menu__item'>
                            <CustomLink to='/parents' className='menu__link'>
                                Пользователям
                            </CustomLink>
                        </li>
                        <li className='menu__item'>
                            <a
                                href='https://driver.taxikids.ru'
                                className='menu__link'
                            >
                                Водителям
                            </a>
                        </li>
                        <li className='menu__item'>
                            <a
                                href='https://legal.taxikids.ru/'
                                target='_blank'
                                className='menu__link'
                                rel='noreferrer'
                            >
                                Документы
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='navigationbar__middle'></div>
                <div className='navigationbar__right'>
                    <div
                        className='navigationbar__user'
                        onClick={handleChangeStateShowLk}
                    >
                        <div className='user__name'>
                            {profile ? profile.firstName : ''}
                        </div>
                        {profile ? (
                            <img
                                src={PhotoUser}
                                height='39px'
                                width='39px'
                                alt='photo-user'
                            />
                        ) : (
                            <img
                                src={PhotoUserNone}
                                height='39px'
                                width='39px'
                                alt='photo-user'
                            />
                        )}
                    </div>
                </div>
            </div>
            {showLk && <ProfileMenu />}
        </nav>
    )
}
