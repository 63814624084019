import Burger from '../../../assets/images/menu.svg'
import PhotoUser from '../../../assets/images/photo-user.svg'
import PhotoUserNone from '../../../assets/images/user-photo-none.svg'
import { Link } from 'react-router-dom'
import { ProfileMenu } from '../../ProfileMenu'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../context/ProfileContext'

export const MobileMenu = () => {
    const [showLk, setShowLk] = React.useState(false)
    const [showMenu, setShowMenu] = React.useState(false)
    const profile = useContext(ProfileContext)

    const handleChangeStateShowLk = () => setShowLk(!showLk)

    return (
        <>
            <div className='burger' onClick={() => setShowMenu(!showMenu)}>
                <img src={Burger} alt='burger-menu-img' />
            </div>
            {showMenu && (
                <>
                    <div
                        className='burger__background'
                        onClick={() => {
                            setShowMenu(false)
                        }}
                    ></div>
                    <div className='burger__wrapper'>
                        <div
                            className='burger__profile'
                            onClick={() => setShowLk(!showLk)}
                        >
                            {profile ? (
                                <img
                                    src={PhotoUser}
                                    height='39px'
                                    width='39px'
                                    alt='photo-user'
                                />
                            ) : (
                                <img
                                    src={PhotoUserNone}
                                    height='39px'
                                    width='39px'
                                    alt='photo-user'
                                />
                            )}
                            <div className='user__name'>
                                {profile ? profile.firstName : ''}
                            </div>
                        </div>
                        <div className='burger__line'></div>

                        <ul className='burger__menu'>
                            <li className='burger__item'>
                                <Link
                                    to='/parents'
                                    className='menu__link burger__link'
                                >
                                    Пользователям
                                </Link>
                            </li>
                            <li className='burger__item'>
                                <a
                                    href='https://driver.taxikids.ru'
                                    className='menu__link burger__link'
                                >
                                    Водителям
                                </a>
                            </li>
                            <li className='burger__item'>
                                <a
                                    href='https://legal.taxikids.ru/'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='menu__link burger__link'
                                >
                                    Документы
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            )}
            {showLk && (
                <>
                    <div
                        className='profile__background'
                        onClick={handleChangeStateShowLk}
                    ></div>
                    <ProfileMenu />
                </>
            )}
        </>
    )
}
