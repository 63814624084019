import { useContext, useState } from 'react'
import ModalKids from '../ModalKids'
import { ProfileContext } from '../../../../context/ProfileContext'

interface PropsType {
    serviceItem: any
    serviceIndex: number
    changeCheckedState: any
    checkedState: any
}

export const ServiceItem = (props: PropsType) => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const profile = useContext(ProfileContext)

    function closeModal() {
        setIsOpenModal(false)
    }

    // function cancelOrder(index: number) {
    //     props.cancelCheckedState(index)
    //     // setIsOpenModal(false)
    // }

    return (
        <>
            <a
                className='service'
                key={props.serviceIndex}
                onClick={(e: any) => {
                    e.preventDefault()
                    if (props.serviceItem?.multiple === true && profile) {
                        setIsOpenModal(true)
                    }

                    props.changeCheckedState(props.serviceIndex)
                }}
            >
                <input
                    type='checkbox'
                    className='custom-check'
                    id='check'
                    checked={
                        typeof props.checkedState[props.serviceIndex] ===
                        'boolean'
                            ? props.checkedState[props.serviceIndex]
                            : props.checkedState[props.serviceIndex].find(
                                  (el: boolean) => el === true
                              )
                            ? true
                            : false
                    }
                    value='new'
                    readOnly
                />
                <label htmlFor='check'>{props.serviceItem.title}</label>
                {props.serviceItem.age ? (
                    <>
                        <label>{props.serviceItem.age}&nbsp;года</label>
                        <span className='price'>
                            {props.serviceItem.price != 0
                                ? '+' + props.serviceItem.price
                                : props.serviceItem.title.length >= 15
                                ? 'бесп'
                                : 'бесплатно'}
                        </span>
                    </>
                ) : (
                    <span className='price'>
                        {props.serviceItem.price != 0
                            ? '+' + props.serviceItem.price
                            : props.serviceItem.title.length >= 15
                            ? 'бесп'
                            : 'бесплатно'}
                    </span>
                )}
            </a>
            {isOpenModal ? (
                <ModalKids
                    closeModal={closeModal}
                    title={props.serviceItem.title}
                    price={props.serviceItem.price}
                    serviceIndex={props.serviceIndex}
                    length={profile ? profile.kids.length : 0}
                    checkedState={props.checkedState}
                    changeCheckedState={props.changeCheckedState}
                />
            ) : (
                <div></div>
            )}
        </>
    )
}
