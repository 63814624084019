import React, { useEffect, useState } from 'react'
import { Map, Marker } from '@2gis/mapgl/global'
import { MapContext } from '../../../context/MapContext'
import { Directions, DirectionsLoadedEvent } from '@2gis/mapgl-directions'
import {Point, PointInput} from '../../../__generated__/types';
import {useCompletePointGeoMutation} from './__generated__/points';

export const MapProvider = ({ children }: { children: JSX.Element }) => {
    const [mapInstance, setMapInstance] = useState<Map>(null!)
    const [directions, setDirections] = useState<Directions>(null!)
    const [points, updatePoints] = useState<Point[]>([])
    const [markers, updateMarkers] = useState<Marker[]>([])
    const [distance, updateDistance] = useState<number>(0)

    useEffect(() => {
        if (points.length < 2) updateDistance(0)
    }, [points.length])

    const [completePointGeo] = useCompletePointGeoMutation({
        variables: {
            input: null!
        }
    })

    let count = 0

    const addPointByCoords = (coords: number[]) => {
        const point: PointInput = {
            longitude: coords[0],
            latitude: coords[1],
            sort: count
        }
        count += 1

        completePointGeo({
            variables: {
                input: point
            }
        })
            .then((data) => data.data?.fillPointGeo)
            .then(
                (value) => value && updatePoints((points) => [...points, value])
            )
    }

    const addPointByAddress = (point: Point) => {
        let newPont = {...point, ...{sort: count}}
        count += 1
        updatePoints((points) => [...points, newPont])
    }

    const removePoint = (point: Point) => {
        if (points.length == 2) directions.clear()
        if (points.length > 1) {
            let newPoints = [...points]
            let newMarkers = [...markers]
            const findId = newPoints.findIndex(
                (prod: Point) => prod.sort === point.sort
            )
            newPoints.splice(findId, 1)

            markers.map((m, index) => {
                if (findId == index) {
                    m.destroy()
                }
            })
            newMarkers.splice(findId, 1)
            updateMarkers(newMarkers)

            newPoints.map((item, index) => {
                item.sort = index
            })
            updatePoints(newPoints)
        } else {
            let newMarkers = [...markers]
            updatePoints([])
            markers.map((m) => {
                m.destroy()
            })
            newMarkers.splice(0, 1)
            updateMarkers(newMarkers)
        }
    }

    const updateOrderPoints = (newPoints: Point[]) => {
        updatePoints(newPoints)
    }

    const directionsLoadedHandler = (directions: DirectionsLoadedEvent) => {
        console.log('set distance')
        const route = directions.routes[0]
        route && updateDistance(route.total_distance)
    }

    const addMarker = (marker: Marker) => {
        updateMarkers((markers) => [...markers, marker])
    }

    const mapContext = {
        mapInstance,
        setMapInstance,
        directions,
        setDirections,
        directionsLoadedHandler,
        points,
        addPointByCoords,
        addPointByAddress,
        markers,
        addMarker,
        distance,
        removePoint,
        updateOrderPoints
    }
    return (
        <MapContext.Provider value={mapContext}>{children}</MapContext.Provider>
    )
}

